import clsx from 'clsx';
import React from 'react';

export type TitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  className?: string;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  /**
   * @deprecated
   */
  noLine?: boolean;
  /**
   * @deprecated
   */
  centerLine?: boolean;
  /**
   * @deprecated
   */
  wideLine?: boolean;
};

const Title: React.FC<TitleProps> = ({ component, className, ...rest }) => {
  const Component = component ?? 'h1';

  return <Component className={clsx(className, `relative font-semibold text-gray-1000 text-3xl`)} {...rest} />;
};

export default Title;
