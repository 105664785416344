import React from 'react';
import clsx from 'clsx';
import { FC } from 'react';
import HyperLink from '../common/HyperLink';

export type ButtonBaseProps = {
  className?: string;
  variant?: 'primary' | 'secondary' | 'cta';
  size?: 'small' | 'normal' | 'big';
  /**
   * @deprecated
   */
  rounded?: boolean;
};

export type ButtonProps = ButtonBaseProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const getVariantClassNames = (variant: ButtonProps['variant'], disabled?: boolean): string => {
  if (variant === 'secondary') {
    if (disabled) return 'font-semibold border-gray-100 border-gray-300 cursor-not-allowed';
    return 'font-semibold  text-gray-1000 border border-gray-300  hover:border-gray-400';
  }

  if (variant === 'cta') {
    if (disabled) return 'border-none text-gray-600 cursor-not-allowed';
    return 'border-none text-primary';
  }

  // primary
  if (disabled) return 'font-semibold bg-gray-300 border-gray-400 cursor-not-allowed';
  return 'font-semibold bg-primary text-white hover:bg-primaryFocus';
};

const getSizeClassNames = (size: ButtonProps['size']): string => {
  if (size === 'small') return 'px-1 w-max-content text-[1rem]';
  if (size === 'big') return 'w-[max-content] px-[1.25rem]  h-[3.25rem]';
  return 'px-[1.25rem] min-w-[10.625rem] xs:min-w-[8.25rem] xs:px-2  h-[2.875rem]';
};

const Button = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { className, variant, size, type, disabled, ...rest } = props;

  return (
    <button
      className={clsx(
        className,
        getSizeClassNames(size),
        getVariantClassNames(variant, disabled),
        'flex rounded flex-nowrap justify-center items-center text-lg font-medium transition-all',
      )}
      type={type ?? 'button'}
      ref={ref}
      disabled={disabled}
      {...rest}
    />
  );
});

Button.displayName = 'Button';

export type LinkButtonProps = Omit<ButtonBaseProps, 'onClick'> & {
  href?: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

export const LinkButton: FC<LinkButtonProps> = ({ className, variant, size, disabled, href, ...rest }) => (
  <HyperLink
    role='link'
    href={href ?? ''}
    aria-disabled={disabled}
    noStyles
    className={clsx(
      className,
      getSizeClassNames(size),
      getVariantClassNames(variant, disabled),
      'flex flex-nowrap justify-center items-center text-lg font-medium transition-all',
    )}
    {...rest}
  />
);

export default Button;
