/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinishOrderProductRequest } from './FinishOrderProductRequest';
export type FinishOrderRequest = {
  serviceDuration: number;
  products: Array<FinishOrderProductRequest>;
  serviceMileage: number;
  /**
   * Units in hours
   */
  serviceTimeOnRoad: number;
  serviceTotalPrice: number | null;
  servicePaymentMethodId: number;
  deliveryPrice: number | null;
  serviceDurationPrice: number | null;
  humidity?: number | null;
  temperature?: number | null;
  deliveryType: FinishOrderRequest.deliveryType;
  deliveryTariffId?: number | null;
  useManualServiceDurationPrice: boolean;
  absentCustomer: boolean;
  workTariffId?: number | null;
  deliveryRateId: number | null;
  preferredCurrency: string;
  signingCustomerName: string | null;
  contactEmail: string | null;
  signingTechnicianId?: number;
  realStart?: string | null;
  realEnd?: string | null;
  slConfigOutput: string | null;
};
export namespace FinishOrderRequest {
  export enum deliveryType {
    CALCULATED = 'CALCULATED',
    TARIFF = 'TARIFF',
    MANUAL = 'MANUAL',
  }
}
