import { BoltIcon, DocumentDuplicateIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'src/components/Button';
import { TranslationItem } from '../../helpers';

export type TranslationsListProps = {
  className?: string;
  translationItems: TranslationItem[];
  defaultTranslations: Record<string, string>;
  onRestoreTranslationFactory: (id: string) => () => void;
  onDeleteTranslationFactory: (id: string) => () => void;
};

const TranslationsList: FC<TranslationsListProps> = ({
  translationItems,
  defaultTranslations,
  onRestoreTranslationFactory,
  onDeleteTranslationFactory,
  className,
}) => {
  const { register, setValue } = useFormContext();

  return (
    <div className={clsx(className, 'flex flex-col max-h-[max(600px,calc(100vh-400px))] overflow-y-auto py-4')}>
      {translationItems.map(({ id, isMatch, isDeleted, isNew }) => (
        <Fragment key={`${id}-${isMatch}-${isNew}`}>
          {isMatch && (
            <div className='flex items-center gap-x-2 w-full py-2'>
              <div className='flex max-w-[calc(100%-24rem)] w-[calc(100%-24rem)]'>
                {isNew && <BoltIcon className='text-primary w-6 min-w-6 mr-1' />}
                <span className={clsx('overflow-x-auto', isDeleted && 'text-gray-500 line-through')}>{id}</span>
                <Button variant='cta' size='small' onClick={() => setValue(id, defaultTranslations[id])}>
                  <DocumentDuplicateIcon className='h-6 ml-1' />
                </Button>
              </div>
              <input
                className={clsx(
                  'w-full max-w-[24rem] border border-gray-300 rounded font-openSans font-normal text-base px-2 h-10',
                  isDeleted ? 'cursor-not-allowed bg-gray-300 text-gray-500' : 'bg-white',
                )}
                disabled={isDeleted}
                {...register(id)}
              />
              {isDeleted ? (
                <Button variant='cta' size='small' onClick={onRestoreTranslationFactory(id)}>
                  <PlusIcon className='h-6 ml-1' />
                </Button>
              ) : (
                <Button variant='cta' size='small' onClick={onDeleteTranslationFactory(id)}>
                  <TrashIcon className='h-6 ml-1' />
                </Button>
              )}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

TranslationsList.displayName = 'TranslationsList';

export default TranslationsList;
