import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useCallback } from 'react';
import { FC, HTMLAttributes, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDeleteUser from 'src/api/hooks/mutations/user/useDeleteUser';
import useRestoreUser from 'src/api/hooks/mutations/user/useRestoreUser';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import useUser from 'src/api/hooks/queries/useUser';
import Button from 'src/components/Button';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import { Modules } from 'src/types/commonTypes';
import UserLimitExceededPopup from '../Users/UserLimitExceededPopup';
import UpdateUserPopup from './UpdateUserPopup';
import UserPreview from './UserPreview';

export type UserDetailPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const UserDetailPage: FC<UserDetailPageProps> = ({ className }) => {
  const userId = Number(useParams().userId);
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const { data: tenant } = useCurrentTenant();
  const getIsModuleValid = useGetIsModuleValid();
  const { isLoading, data } = useUser(userId);
  const [showLimitPopup, setShowLimitPopup] = useState(false);
  const navigate = useNavigate();
  const deleteUser = useDeleteUser();
  const restoreUser = useRestoreUser();
  const openPopup = useOpenPopup('update_user');

  const handleToggleActive = useCallback(() => {
    if (data?.deletedAt) return restoreUser.mutate(userId);
    deleteUser.mutate(userId);
  }, [data, restoreUser, deleteUser]);
  if (!getIsModuleValid(Modules.ADMINISTRATION)) return <Navigate to='/' />;
  if (isLoading || isLoadingCurrentUser) return <Loader />;
  if (!data || !tenant) return <Navigate to='/404' />;

  const isEditingCurrentUser = data?.id === currentUser?.id;
  const buttonAction = data?.deletedAt ? 'restore' : 'edit';
  const hasExceededUserLimit = tenant?.currentUserCount >= tenant?.maxUserCount;

  return (
    <>
      <div className={clsx(className, 'flex flex-col')}>
        <PageContentHeader
          className='mb-20'
          title={
            <div className='flex items-center'>
              <FormattedMessage id='app.users_detail_page.title' />
              <Button
                variant='cta'
                size='small'
                disabled={isEditingCurrentUser}
                onClick={hasExceededUserLimit ? () => setShowLimitPopup(true) : handleToggleActive}
              >
                {buttonAction === 'restore' ? <EyeIcon className='h-6 mr-1' /> : <EyeSlashIcon className='h-6 mr-1' />}
              </Button>
            </div>
          }
          primaryButtonText={<FormattedMessage id='app.user.edit_user.buttton' />}
          onPrimaryButtonClick={openPopup}
          secondaryButtonText={
            <>
              <BackIcon />
              <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
            </>
          }
          onSecondaryButtonClick={() => navigate(-1)}
          mobilePrimaryButtonType='edit'
        />
        <UserPreview data={data} />
      </div>
      <UpdateUserPopup />
      <UserLimitExceededPopup open={showLimitPopup} onClose={() => setShowLimitPopup(false)} />
    </>
  );
};

UserDetailPage.displayName = 'UserDetailPage';

export default UserDetailPage;
