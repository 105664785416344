import { Step } from 'react-joyride';
import { MessageId } from 'src/types/commonTypes';

export const adminJoyrideSteps: Array<Omit<Step, 'content'> & { content: MessageId }> = [
  {
    target: '[data-joyride="settings"]',
    content: 'app.joyride.admin_joyride.settings',
    placement: 'right-end',
    placementBeacon: 'right-end',
    disableScrollParentFix: true,
    disableScrolling: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/settings',
    },
  },
  {
    target: '[data-joyride="parameters"]',
    content: 'app.joyride.admin_joyride.parameters',
    placement: 'right-end',
    placementBeacon: 'right-end',
    disableScrollParentFix: true,
    disableScrolling: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/parameter',
    },
  },
  {
    target: '[data-joyride="profile-settings"]',
    content: 'app.joyride.admin_joyride.profile_settigns',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/profile-settings',
    },
  },
  {
    target: '[data-joyride="imports"]',
    content: 'app.joyride.admin_joyride.imports',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/imports',
    },
  },
  {
    target: '[data-joyride="exports"]',
    content: 'app.joyride.admin_joyride.exports',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/exports',
    },
  },
  {
    target: '[data-joyride="notifications"]',
    content: 'app.joyride.admin_joyride.notifications',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/notification',
    },
  },
  {
    target: '[data-joyride="notifications.customer_sms"]',
    content: 'app.joyride.admin_joyride.notifications.customer_sms',
    disableScrollParentFix: true,
    disableScrolling: true,
    disableBeacon: true,
    placement: 'right',
    data: {
      scrollToTop: true,
      href: '/notification',
    },
  },
  {
    target: '[data-joyride="notifications.employee_email"]',
    content: 'app.joyride.admin_joyride.notifications.employee_email',
    disableScrollParentFix: false,
    disableScrolling: true,
    disableBeacon: true,
    placement: 'right',
    data: {
      scrollToTop: true,
      href: '/notification',
    },
  },
  {
    target: '[data-joyride="users"]',
    content: 'app.joyride.admin_joyride.users',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/user',
    },
  },
  {
    target: '[data-joyride="payment_methods"]',
    content: 'app.joyride.admin_joyride.payment_methods',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/payment-method',
    },
  },
  {
    target: '[data-joyride="routine_services"]',
    content: 'app.joyride.admin_joyride.routine_services',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/routine-services',
    },
  },
  {
    target: '[data-joyride="delivery_rates"]',
    content: 'app.joyride.admin_joyride.delivery_rates',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/delivery-rates',
    },
  },
  {
    target: '[data-joyride="delivery_tariffs"]',
    content: 'app.joyride.admin_joyride.delivery_tariffs',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/delivery-tariffs',
    },
  },
  {
    target: '[data-joyride="work_tariffs"]',
    content: 'app.joyride.admin_joyride.work_tariffs',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/work-tariffs',
    },
  },
  {
    target: '[data-joyride="technician_profile"]',
    content: 'app.joyride.admin_joyride.technician_profile',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/technician-account',
    },
  },
  {
    target: '[data-joyride="material"]',
    content: 'app.joyride.admin_joyride.material',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/material',
    },
  },
  {
    target: '[data-joyride="calendar"]',
    content: 'app.joyride.admin_joyride.calendar',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/calendar',
    },
  },
  {
    target: '[data-joyride="branches"]',
    content: 'app.joyride.admin_joyride.branches',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/branch',
    },
  },
  {
    target: '[data-joyride="customers"]',
    content: 'app.joyride.admin_joyride.customers',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/contact',
    },
  },
  {
    target: '[data-joyride="orders"]',
    content: 'app.joyride.admin_joyride.orders',
    placement: 'right',
    disableScrollParentFix: true,
    disableBeacon: true,
    data: {
      scrollToTop: true,
      href: '/order',
    },
  },
] as const;
