import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { adminJoyrideState } from 'src/store/atoms/joyrides';
import { adminJoyrideSteps } from './steps';
import sleep from 'src/utils/sleep';

export type AdminJoyrideProps = {
  //
};

const AdminJoyride: FC<AdminJoyrideProps> = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { run, stepIndex } = useRecoilValue(adminJoyrideState);
  const setState = useSetRecoilState(adminJoyrideState);

  const steps = useMemo<Array<Step>>(
    () => adminJoyrideSteps.map((step) => ({ ...step, content: intl.formatMessage({ id: step.content }) })),
    [],
  );

  const joyrideCallback = async (props: CallBackProps): Promise<void> => {
    const { action, index, status, type, step } = props;

    if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER && index > 0) {
      const prevStep = adminJoyrideSteps[index - 1];
      const prevHref = prevStep?.data?.href;
      if (!prevHref) return;

      navigate(prevHref);
      // targets in scrollable container are not working when body is scrolled
      if (prevStep?.data?.scrollToTop) window.scrollTo(0, 0);
      await sleep(100);
    }

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setState({ run: false, stepIndex: index });
      return;
    }

    if (type === EVENTS.STEP_BEFORE) {
      if (step?.data?.href) navigate(step?.data?.href);
      // targets in scrollable container are not working when body is scrolled
      if (step?.data?.scrollToTop) window.scrollTo(0, 0);
      await sleep(100);
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (nextStepIndex < 0 || nextStepIndex >= steps.length) {
        setState({ run: false, stepIndex: 0 });
      } else {
        setState({ run: true, stepIndex: nextStepIndex });
      }
    }
  };

  return (
    <Joyride
      run={run}
      hideCloseButton
      showSkipButton
      showProgress
      continuous
      callback={joyrideCallback}
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        overlay: {
          height: '100vh',
          maxHeight: '100vh',
          scrollBehavior: 'unset',
          position: 'fixed',
        },
        spotlight: {},
        tooltip: {
          fontSize: '1.25rem',
        },
        buttonNext: {
          fontSize: '1.25rem',
          backgroundColor: 'var(--primary-color)',
        },
        buttonBack: {
          fontSize: '1.25rem',
          color: 'var(--primary-color)',
        },
        buttonSkip: {
          fontSize: '1.25rem',
        },
      }}
      locale={{
        back: intl.formatMessage({ id: 'app.buttons.back' }),
        next: intl.formatMessage({ id: 'app.button.next' }),
        skip: intl.formatMessage({ id: 'app.buttons.skip' }),
        last: intl.formatMessage({ id: 'app.button.last' }),
      }}
    />
  );
};

export default AdminJoyride;
