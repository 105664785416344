import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ContactListResponse, useApiClient } from 'src/api';
import useContactProducts from 'src/api/hooks/queries/useContactProducts';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import AutocompleteBe from 'src/components/fields/AutocompleteBe/AutocompleteBe';
import DescriptionField from 'src/components/fields/DescriptionField';
import Select from 'src/components/fields/Select';
import FormDataRow from 'src/components/FormDataRow';
import { StockOrderMaterialSchema, StockOrderSchema } from './schema';
import Button from 'src/components/Button';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { zodResolver } from '@hookform/resolvers/zod';

export type StockOrdersFormProps = {
  className?: string;
  defaultValues?: Partial<StockOrderSchema>;
  onSubmit: SubmitHandler<StockOrderSchema>;
};

const DEFAULT_VALUES = {
  text: '',
  companyId: null,
  productId: null,
  materials: [{ materialId: null, quantity: 1 }],
} as unknown as StockOrderSchema;

const StockOrdersForm: FC<StockOrdersFormProps> = ({ className, defaultValues, onSubmit }) => {
  const apiClient = useApiClient();

  const form = useForm<StockOrderSchema>({
    resolver: zodResolver(StockOrderSchema),
    defaultValues: defaultValues ?? DEFAULT_VALUES,
  });

  const { handleSubmit, setValue, control, watch } = form;

  const { fields: materials, append, remove } = useFieldArray({ name: 'materials', control });

  const companyId = watch('companyId');

  const { data: contactProducts } = useContactProducts(companyId ?? 0, {
    enabled: !!companyId,
  });

  const getContacts = useCallback(
    async (query: string): Promise<ContactListResponse[]> => {
      const data = await apiClient.contact.getContactList({
        filterString: query,
        orderBy: 'company_name',
        direction: 'ASC',
        pageSize: 20,
        pageIndex: 0,
        withDeleted: false,
      });
      return data.items;
    },
    [apiClient],
  );

  const getMaterials = useCallback(
    async (query: string) => {
      const data = await apiClient.material.searchMaterials({ query, limit: 20 });
      return data ?? [];
    },
    [apiClient],
  );

  const handleContactChange = async (): Promise<void> => {
    setValue('productId', null as unknown as number);
  };

  return (
    <FormProvider {...form}>
      <form className={clsx('flex flex-col w-full', className)} onSubmit={handleSubmit(onSubmit)}>
        <FormDataRow labelWidthClass='w-48' asterisk label={<FormattedMessage id='app.stock_order.customer' />}>
          <Controller
            control={control}
            name='companyId'
            render={({ field: { onChange, ...rest } }) => (
              <AutocompleteBe
                {...rest}
                onChange={(v: number | null) => {
                  onChange(v);
                  handleContactChange();
                }}
                getOptions={getContacts}
                getOptionLabel={(opt) => `${opt.companyName}${opt.vat ? ` - ${opt.vat}` : ''}`}
                getOptionValue={(opt) => opt.id}
              />
            )}
          />
        </FormDataRow>
        <FormDataRow labelWidthClass='w-48' asterisk label={<FormattedMessage id='app.stock_order.product_name' />}>
          <Controller
            control={control}
            name={`productId`}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={contactProducts ?? []}
                getOptionLabel={(option) =>
                  option.productSerialNumber
                    ? `${option.productName} (${option.productSerialNumber})`
                    : option.productName
                }
                getOptionValue={(option) => option.id}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormDataRow>
        <FormDataRow
          labelWidthClass='w-48'
          asterisk
          alignItems='flex-start'
          label={
            <div className='flex items-center'>
              <span>
                <FormattedMessage id='app.stock_order.material' />
              </span>
              <Button
                variant='cta'
                size='small'
                onClick={() => append({ materialId: null, quantity: 1 } as unknown as StockOrderMaterialSchema)}
              >
                <PlusCircleIcon className='h-6 ml-1' />
              </Button>
            </div>
          }
        >
          <div className='flex flex-col w-full gap-y-2'>
            {materials.map((material, index) => (
              <div className='flex gap-2 w-full' key={material.id}>
                <Controller
                  control={control}
                  name={`materials.${index}.materialId`}
                  render={({ field }) => (
                    <AutocompleteBe
                      {...field}
                      getOptions={getMaterials}
                      getOptionLabel={(opt) => `${opt.materialName} (${opt.materialCode}})`}
                      getOptionValue={(opt) => opt.id}
                    />
                  )}
                />
                <NumberFieldRhfc control={control} name={`materials.${index}.quantity`} className='max-w-1/4' />
                {materials.length > 1 && (
                  <Button variant='cta' size='small' onClick={() => remove(index)}>
                    <TrashIcon className='h-6 ml-1' />
                  </Button>
                )}
              </div>
            ))}
          </div>
        </FormDataRow>
        <FormDataRow labelWidthClass='w-48' asterisk label={<FormattedMessage id='app.stock_order.text' />}>
          <Controller name='text' control={control} render={({ field }) => <DescriptionField {...field} />} />
        </FormDataRow>
        <NewItemPopupFooter />
      </form>
    </FormProvider>
  );
};

StockOrdersForm.displayName = 'StockOrdersForm';

export default StockOrdersForm;
