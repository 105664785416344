import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { SavedOrderFormResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useSavedOrderForm = (orderId: number): UseQueryResult<SavedOrderFormResponse> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.savedOrderForms.detail(orderId);

  return useQuery(queryKey, () => client.savedOrderForms.findOne({ orderId }), {
    refetchInterval: false,
    staleTime: Infinity,
  });
};

export default useSavedOrderForm;
