import { z } from 'zod';

export const StockOrderMaterialSchema = z.object({
  materialId: z.number(),
  quantity: z.number(),
});

export type StockOrderMaterialSchema = z.infer<typeof StockOrderMaterialSchema>;

export const StockOrderSchema = z.object({
  productId: z.number().nullable().default(null),
  materials: z.array(StockOrderMaterialSchema),
  companyId: z.number().nullable().default(null),
  text: z.string().nullable().default(null),
});

export type StockOrderSchema = z.infer<typeof StockOrderSchema>;
