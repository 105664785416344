import { createColumnHelper } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { OrderListItemResponse } from 'src/api';
import CheckIcon from 'src/components/Icons/CheckIcon';
import StatusLabel from 'src/components/StatusLabel';
import Table, { TableProps } from 'src/components/Table';
import DateFilter from 'src/components/Table/filters/DateFilter';
import TextFilter from 'src/components/Table/filters/TextFilter';
import { ExtendedColumnDef } from 'src/types/table';
import ActionsCell from './cells/ActionsCell';
import CategoryFilter from './filters/CategoryFilter';
import OrderTypeFilter from './filters/OrderTypeFilter';
import StatusFilter from './filters/StatusFilter';
import TechniciansCell from './cells/TechniciansCell';
import CustomerCell from './cells/CustomerCell';

export type ServiceTableProps = Omit<TableProps<OrderListItemResponse>, 'columns'> & {
  columns?: string[];
};

const columnHelper = createColumnHelper<OrderListItemResponse>();

export const ORDERS_TABLE_COLUMNS: ExtendedColumnDef<OrderListItemResponse>[] = [
  {
    ...columnHelper.accessor('orderNumber', {
      id: 'orderNumber',
      header: () => <FormattedMessage id='app.order.number' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.renderValue(),
      size: 90,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('orderType', {
      id: 'orderType.name',
      header: () => <FormattedMessage id='app.order.order_type' />,
      enableColumnFilter: false,
      enableSorting: true,
      cell: (info) => info.getValue()?.name,
      size: 200,
    }),
    renderFilter: (column) => <OrderTypeFilter column={column} />,
  },
  {
    ...columnHelper.accessor('category', {
      id: 'category',
      header: () => <FormattedMessage id='app.customer.category' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 120,
    }),
    renderFilter: (column) => <CategoryFilter column={column} />,
  },
  {
    ...columnHelper.accessor('companyName', {
      id: 'company.companyName',
      enableColumnFilter: true,
      enableSorting: true,
      header: () => <FormattedMessage id='app.order.company_name' />,
      cell: (info) => <CustomerCell info={info} />,
      size: 180,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('technicians', {
      id: 'technicians.profile.name',
      header: () => <FormattedMessage id='app.order.technicians' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => <TechniciansCell info={info} />,
      // cell: (info) =>
      //   info
      //     .getValue()
      //     ?.map((i: UserResponse) => i?.profile?.name)
      //     .join(', '),
      size: 180,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('datetime', {
      id: 'datetime',
      header: () => <FormattedMessage id='app.order.datetime' />,
      enableColumnFilter: true,
      enableSorting: true,
      cell: (info) => {
        try {
          return format(parseISO(info.getValue()), 'dd/MM/yyyy');
        } catch (_) {
          return '';
        }
      },
    }),
    renderFilter: (column) => <DateFilter column={column} />,
  },
  {
    ...columnHelper.accessor((info) => info.status?.name, {
      id: 'status.id',
      header: () => <FormattedMessage id='app.order.status' />,
      enableSorting: true,
      enableColumnFilter: true,
      cell: (info) => <StatusLabel name={info.getValue()} />,
      size: 120,
    }),
    renderFilter: (column) => <StatusFilter column={column} />,
  },
  {
    ...columnHelper.accessor('productName', {
      id: 'productName',
      header: () => <FormattedMessage id='app.order.product_names' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 120,
    }),
    renderFilter: (column) => <TextFilter column={column} />,
  },
  {
    ...columnHelper.accessor('defectDescription', {
      id: 'defectDescription',
      header: () => <FormattedMessage id='app.order.problem_description' />,
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info) => (info.getValue()?.length > 65 ? info.getValue().slice(0, 62) + '...' : info.renderValue()),
      size: 250,
    }),
  },
  {
    ...columnHelper.accessor((o) => o.branch?.name, {
      id: 'branch.name',
      header: () => <FormattedMessage id='app.order.branch_name' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 120,
    }),
  },
  {
    ...columnHelper.accessor((o) => o.employee?.name, {
      id: 'employee.name',
      header: () => <FormattedMessage id='app.order.employee_name' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 120,
    }),
  },
  {
    ...columnHelper.accessor('serviceTotalPrice', {
      id: 'serviceTotalPrice',
      header: () => <FormattedMessage id='app.finish_order.service_total_price_without_vat' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 120,
    }),
  },
  {
    ...columnHelper.accessor('materialPrice', {
      id: 'materialPrice',
      header: () => <FormattedMessage id='app.finish_order.material_price.without_vat' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 120,
    }),
  },
  {
    ...columnHelper.accessor((o) => o.deliveryTariff?.name, {
      id: 'deliveryTariff.name',
      header: () => <FormattedMessage id='app.order.delivery_tariff_name' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 160,
    }),
  },
  {
    ...columnHelper.accessor((o) => o.workTariff?.name, {
      id: 'workTariff.name',
      header: () => <FormattedMessage id='app.order.work_tariff_name' />,
      enableSorting: false,
      enableColumnFilter: false,
      size: 160,
    }),
  },
  {
    ...columnHelper.accessor((o) => o.vatTariff?.tariffValue, {
      id: 'vatTariff.name',
      header: () => <FormattedMessage id='app.order.vat_tariff' />,
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info) => (info.getValue !== undefined ? `${info.getValue()}%` : null),
      size: 100,
    }),
  },
  {
    ...columnHelper.display({
      id: 'actions',
      enableSorting: false,
      enableColumnFilter: false,
      header: () => <CheckIcon />,
      cell: (info) => <ActionsCell info={info} />,
      size: 50,
    }),
    disableRowClick: true,
  },
];

const ServiceTable: React.FC<ServiceTableProps> = (props) => {
  const { columns } = props;

  const shownColumns = useMemo(() => {
    if (!columns) return ORDERS_TABLE_COLUMNS;
    const filteredColumns = ORDERS_TABLE_COLUMNS.filter((c) => columns.includes(c.id as string));
    const sortedColumns = filteredColumns.sort(
      (a, b) => columns.indexOf(a.id as string) - columns.indexOf(b.id as string),
    );
    return sortedColumns;
  }, [columns]);
  const navigate = useNavigate();

  return <Table {...props} columns={shownColumns} handleRowClick={(original) => navigate(`/order/${original.id}`)} />;
};

export default ServiceTable;
