import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmationPopupProvider } from './hooks/popups/useWithConfirmationPopup';
import useIsTouchDevice from './hooks/useIsTouchDevice';
import useUserTranslationPack from './hooks/useUserTranslationPack';
import { AutoPopupsProvider } from './store/autoPopupsContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import './App.css';
import { useMemo } from 'react';
import { TranslationsDictionary } from './types/commonTypes';
import isDesktopOS from './helpers/isDesktopOS';
import useDefaultTranslations from './api/hooks/queries/useDefaultTranslations';
import i18next from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import translationCs from './translations/zod-cs.json';
import translationSk from './translations/zod-sk.json';
import translationEn from 'zod-i18n-map/locales/en/zod.json';
import { InfoPopupProvider } from './components/InfoPopup/InfoPopupProvider';
import MainRouter from './routers/MainRouter';

// probably shoud create own mapper instead of using i18next & zod-i18n-map
i18next.init({
  lng: 'cs',
  resources: {
    cs: { zod: translationCs },
    sk: { zod: translationSk },
    en: { zod: translationEn },
  },
});
z.setErrorMap(zodI18nMap);

const App: React.FC = () => {
  const { data: defaultTranslations } = useDefaultTranslations();
  const { data: translationsPack } = useUserTranslationPack();
  const isTouchDevice = useIsTouchDevice();
  const mergedTranslations = useMemo(
    () => ({ ...defaultTranslations, ...translationsPack?.json } as TranslationsDictionary),
    [defaultTranslations, translationsPack],
  );

  return (
    <IntlProvider locale='cs-CZ' onError={() => undefined} messages={mergedTranslations}>
      <DndProvider backend={isTouchDevice && !isDesktopOS() ? TouchBackend : HTML5Backend}>
        <AutoPopupsProvider>
          <ConfirmationPopupProvider>
            <InfoPopupProvider>
              <MainRouter />
              <ToastContainer position='bottom-left' hideProgressBar theme='colored' />
              <ReactQueryDevtools initialIsOpen={false} />
            </InfoPopupProvider>
          </ConfirmationPopupProvider>
        </AutoPopupsProvider>
      </DndProvider>
    </IntlProvider>
  );
};

export default App;
