import { FC, HTMLAttributes, useCallback } from 'react';
import ContactForm from '../ContactForm';
import { usePopupContext } from 'src/components/Popup/hook';
import useAddContact from 'src/api/hooks/mutations/contact/useAddContact';
import { SubmitHandler } from 'react-hook-form';
import { CreateContactRequest } from 'src/api';

export type AddContactFormProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AddContactForm: FC<AddContactFormProps> = ({ className }) => {
  const addContact = useAddContact();
  const { onClose } = usePopupContext();

  const onSubmit: SubmitHandler<CreateContactRequest> = useCallback(
    (data) => {
      addContact.mutate(data);
      onClose();
    },
    [addContact, onClose],
  );

  return <ContactForm className={className} onSubmit={onSubmit} />;
};

AddContactForm.displayName = 'AddContactForm';

export default AddContactForm;
