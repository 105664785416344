import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import FormDataRow, { FormDataRowProps } from 'src/components/FormDataRow';
import { TextFieldControlled } from '../Textfield';
import HelperText from 'src/components/common/HelperText';
import { equals } from 'ramda';

export type MultiInputBaseProps<T> = {
  className?: string;
  name: string;
  label: ReactNode;
  onChange: (value: T) => void;
  inputsToValue: (inputs: InputData[]) => T;
  valueToInputs: (value: T) => InputData[];
  value: T;
  FormDataRowProps?: Omit<FormDataRowProps, 'children' | 'label'>;
  error?: string;
  hideErrorMessage?: boolean;
  disabled?: boolean;
};

export type InputData = {
  id: string;
  value: string;
};

export const inputDataFactory = (value = ''): InputData => ({
  id: nanoid(),
  value,
});

const MultiInputBase = <T,>({
  FormDataRowProps,
  className,
  name,
  onChange,
  label,
  inputsToValue,
  valueToInputs,
  value,
  hideErrorMessage,
  error,
  disabled,
}: MultiInputBaseProps<T>): JSX.Element => {
  const [inputs, setInputs] = useState<InputData[]>(() => (value ? valueToInputs(value) : [inputDataFactory()]));
  const addInput = useCallback(() => setInputs((prev) => [...prev, inputDataFactory()]), []);

  const deleteInputFactory = useCallback(
    (id: string) => () => setInputs((prev) => prev.filter((i) => i.id !== id)),
    [],
  );

  const setInputValue = useCallback((id: string, value: string) => {
    setInputs((prev) => {
      const index = prev.findIndex((i) => i.id === id);
      const newInputs = [...prev];
      newInputs[index] = { ...newInputs[index], value };
      return newInputs;
    });
  }, []);

  const handleChangeFactory = useCallback(
    (id: string) => (value: string | null | undefined) => {
      if (!value) return setInputValue(id, '');
      setInputValue(id, value);
    },
    [setInputValue],
  );

  useEffect(() => {
    const inputsWithValue = inputs.filter((i) => i.value.length > 0);
    const value = inputsToValue(inputsWithValue);

    onChange(value);
  }, [inputs]);

  // TODO: refactor
  useEffect(() => {
    const nextInputs = valueToInputs(value);
    const values = inputs.map((i) => i.value);
    const nextValues = nextInputs.map((i) => i.value);
    if (equals(values, nextValues)) return;

    if (!value) {
      setInputs([inputDataFactory()]);
      return;
    }

    setInputs(nextInputs);
  }, [value]);

  return (
    <FormDataRow
      {...FormDataRowProps}
      labelClassName={clsx(FormDataRowProps?.labelClassName, 'self-start')}
      label={
        <label className='flex' htmlFor={name}>
          {label}
          <Button disabled={disabled} variant='cta' size='small' onClick={addInput}>
            <PlusCircleIcon className='h-6 ml-1' />
          </Button>
        </label>
      }
    >
      <div className={clsx(className, 'flex flex-col gap-y-2 w-full')}>
        {inputs.map((inputData, index) => (
          <div className='flex w-full' id={name} key={inputData.id}>
            <TextFieldControlled
              disabled={disabled}
              name={`${name}[${index}]`}
              onChange={handleChangeFactory(inputData.id)}
              value={inputData.value}
            />
            {inputs.length > 1 && (
              <Button disabled={disabled} variant='cta' size='small' onClick={deleteInputFactory(inputData.id)}>
                <TrashIcon className='h-6' />
              </Button>
            )}
          </div>
        ))}
        {!hideErrorMessage && error && <HelperText variant='error'>{error}</HelperText>}
      </div>
    </FormDataRow>
  );
};

MultiInputBase.displayName = 'MultiInputBase';

export default MultiInputBase;
