import clsx from 'clsx';
import { mergeDeepRight } from 'ramda';
import { FC } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { OrderStockConfigDto, UpdateOrderStockConfigRequest } from 'src/api';
import useUpdateStockOrderConfig from 'src/api/hooks/mutations/tenantConfig/useUpdateStockOrderConfig';
import Button from 'src/components/Button';
import MultiInputBase, { inputDataFactory } from 'src/components/fields/MultiInput';
import Title from 'src/components/Title';

export type StockOrdersNotificationProps = {
  className?: string;
  orderStockConfig?: OrderStockConfigDto;
};

const defaultValues: Partial<OrderStockConfigDto> = {
  notificationEmails: [''],
};

const StockOrdersNotification: FC<StockOrdersNotificationProps> = ({ className, orderStockConfig }) => {
  const form = useForm<UpdateOrderStockConfigRequest>({
    defaultValues: mergeDeepRight(defaultValues, orderStockConfig ?? {}),
  });
  const updateStockOrderConfig = useUpdateStockOrderConfig();

  const onSubmit: SubmitHandler<UpdateOrderStockConfigRequest> = (data) => {
    updateStockOrderConfig.mutate(data);
  };

  const { control, handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form className={clsx(className, 'flex flex-col max-w-xl')} onSubmit={handleSubmit(onSubmit, console.error)}>
        <Title className='text-2xl mb-8'>
          <FormattedMessage id='app.stock_order.notification' />
        </Title>
        <Controller
          name='notificationEmails'
          control={control}
          render={({ field: { value, onChange } }) => (
            <MultiInputBase<string[]>
              name='notificationEmails'
              label={<FormattedMessage id='app.stock_order.notification_emails' />}
              value={value ?? []}
              onChange={onChange}
              inputsToValue={(inputs) => inputs.map((i) => i.value)}
              valueToInputs={(value) => value.map(inputDataFactory) ?? []}
              FormDataRowProps={{
                labelWidthClass: 'w-48',
              }}
            />
          )}
        />
        <Button rounded type='submit'>
          <FormattedMessage id='app.buttons.save' />
        </Button>
      </form>
    </FormProvider>
  );
};

StockOrdersNotification.displayName = 'StockOrdersNotification';

export default StockOrdersNotification;
