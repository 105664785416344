import { ChangeEventHandler, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Badge from 'src/components/common/Badge';
import ImportCard from './ImportCard';
import { toast } from 'react-toastify';
import saveBlob from 'src/helpers/saveBlob';
import { utils, write, read } from 'xlsx';
import { useApiClient } from 'src/api';
import { ContactSchema } from 'src/pages/ContactPage/ContactForm/schema';
import { z } from 'zod';

const ImportCustomersCard: FC = () => {
  const intl = useIntl();
  const apiClient = useApiClient();

  const handleCreateTemplate = (): void => {
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet([], {
      header: [
        intl.formatMessage({ id: 'app.common.vat' }),
        intl.formatMessage({ id: 'app.contact.company_name' }),
        intl.formatMessage({ id: 'app.common.email' }),
        intl.formatMessage({ id: 'app.common.phone' }),
        intl.formatMessage({ id: 'app.common.city' }),
        intl.formatMessage({ id: 'app.common.street' }),
        intl.formatMessage({ id: 'app.common.zip' }),
        intl.formatMessage({ id: 'app.common.country' }) + ' (cz, sk)',
        intl.formatMessage({ id: 'app.common.preferred_currency' }) + ' (CZK, EUR)',
        intl.formatMessage({ id: 'app.common.preferred_language' }) + ' (cz, sk, en)',
        intl.formatMessage({ id: 'app.contact.ico' }),
      ],
    });
    utils.book_append_sheet(workbook, worksheet);
    const buffer = write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveBlob(blob, 'customers_template.xlsx');
  };

  const handleImport: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();

    reader.onload = async (e) => {
      const wb = read(e.target?.result as ArrayBuffer);
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: [
          'vat',
          'companyName',
          'email',
          'phoneNumber',
          'city',
          'street',
          'zip',
          'country',
          'preferredCurrency',
          'preferredLanguage',
          'in',
        ],
      }) as Array<Record<string, unknown>>;
      for (const row of data) {
        if (typeof row.in === 'number') {
          row.in = row.in.toString();
        }
      }
      const cleanData = data.slice(1).map(convertDataToContactSchema);
      if (!cleanData.length) {
        toast(<FormattedMessage id='app.toast.no_data' />, {
          type: 'error',
        });
        return;
      }
      const schema = z.array(ContactSchema);
      try {
        const validated = schema.parse(cleanData);
        if (!validated) return;
        await apiClient.contact.createContactList({
          requestBody: validated,
        });
        toast(<FormattedMessage id='app.toast.import_success' />, {
          type: 'success',
        });
      } catch (err) {
        console.error(err);
        toast(<FormattedMessage id='app.toast.validation_error' />, {
          type: 'error',
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <ImportCard
      title={<FormattedMessage id='app.imports_page.cards.customer.title' />}
      description={<FormattedMessage id='app.imports_page.cards.customer.description' />}
      handleDownloadTemplate={handleCreateTemplate}
      handleImport={handleImport}
      badge={
        <>
          <div className='flex gap-x-2'>
            <Badge text={<FormattedMessage id='app.common.excel' />} color='green' />
          </div>
        </>
      }
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertDataToContactSchema(data: any): Partial<ContactSchema> {
  return {
    vat: data.vat?.toString(),
    companyName: data.companyName?.toString(),
    email: data.email?.toString(),
    phoneNumber: data.phoneNumber?.toString(),
    city: data.city?.toString(),
    street: data.street?.toString(),
    zip: data.zip?.toString(),
    country: data.country?.toString(),
    preferredCurrency: data.preferredCurrency?.toString(),
    preferredLanguage: data.preferredLanguage?.toString(),
    in: data.in?.toString(),
  };
}

export default ImportCustomersCard;
