import { flatten, isNil, map, pipe, pluck, propSatisfies, reject, sum } from 'ramda';
import { useFormContext } from 'react-hook-form';
import { CreateOrderMaterialRequest, OrderResponse } from 'src/api';
import useAllDeliveryTariffs from 'src/api/hooks/queries/useAllDeliveryTariffs';
import useAllPaymentMethods from 'src/api/hooks/queries/useAllPaymentMethods';
import useAllWorkTariffs from 'src/api/hooks/queries/useAllWorkTariffs';
import { FinishOrderFormValues } from '../components/FinishOrderForm/schema';
import useAllDeliveryRates from 'src/api/hooks/queries/useAllDeliveryRates';
import getTariffPriceInCurrency from 'src/helpers/getTariffPriceInCurrency';

const calculateTotalPrice = (materials: CreateOrderMaterialRequest[]): number =>
  pipe(
    reject(propSatisfies(isNil, 'price')),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reject(propSatisfies(isNil, 'quantity')),
    map((i) => (i.price ?? 0) * (i.quantity ?? 0)),
    sum,
  )(materials) as number;

export type UseFinishOrderPriceReturn = {
  finalPriceWithoutVat: number;
  finalPriceWithVat: number;
};

const roundTo2Decimals = (num: number): number => Math.round((num + Number.EPSILON) * 100) / 100;
const roundToFull = (num: number): number => Math.round(num);

const useDeliveryPrice = (order: OrderResponse): number => {
  const { headquarters } = order;
  const { watch } = useFormContext<FinishOrderFormValues>();

  const deliveryType = watch('deliveryType');
  const deliveryTariffId = watch('deliveryTariffId');
  const deliveryRateId = watch('deliveryRateId');
  const deliveryPriceWatch = watch('deliveryPrice') ?? 0;
  const preferredCurrency = watch('preferredCurrency');
  const serviceMileage = watch('serviceMileage') ?? 0;
  const serviceTimeOnRoad = watch('serviceTimeOnRoad') ?? 0;

  const { data: deliveryTariffs } = useAllDeliveryTariffs({
    enabled: deliveryType === OrderResponse.deliveryType.TARIFF,
  });

  const { data: deliveryRates } = useAllDeliveryRates({
    enabled: deliveryType === OrderResponse.deliveryType.CALCULATED,
  });

  const serviceMileageRate = headquarters?.serviceMileageRate ?? 0;
  const serviceTimeOnRoadRate = headquarters?.serviceTimeOnRoadRate ?? 0;

  const deliveryTariff = deliveryTariffs?.find((i) => i.id === deliveryTariffId);
  const deliveryRate = deliveryRates?.find((i) => i.id === deliveryRateId);

  if (deliveryType === OrderResponse.deliveryType.MANUAL) return deliveryPriceWatch;

  if (deliveryType === OrderResponse.deliveryType.TARIFF) {
    if (!deliveryTariff) return 0;
    return getTariffPriceInCurrency(deliveryTariff, preferredCurrency);
  }

  const serviceTimeOnRoadPrice = serviceTimeOnRoad * serviceTimeOnRoadRate;

  if (!deliveryRate) return serviceMileage * serviceMileageRate + serviceTimeOnRoadPrice;

  const deliveryRatePrice = getTariffPriceInCurrency(deliveryRate, preferredCurrency);
  return serviceMileage * deliveryRatePrice + serviceTimeOnRoadPrice;
};

const useServiceDurationPrice = (order: OrderResponse): number => {
  const { headquarters } = order;
  const { watch } = useFormContext<FinishOrderFormValues>();
  const { data: workTariffs } = useAllWorkTariffs();

  const manualWorkPrice = watch('useManualServiceDurationPrice');
  const manualWorkPriceWatch = watch('serviceDurationPrice') ?? 0;

  if (manualWorkPrice) return manualWorkPriceWatch;

  const preferredCurrency = watch('preferredCurrency');
  const workTariffId = watch('workTariffId');

  const workTariff = workTariffs?.find((i) => i.id === workTariffId);

  const serviceDurationRate = workTariffId
    ? preferredCurrency === 'CZK'
      ? workTariff?.price ?? 0
      : workTariff?.priceEur ?? 0
    : headquarters?.serviceDurationRate ?? 0;

  const serviceDuration = watch('serviceDuration') ?? 0;
  const serviceDurationPrice = serviceDuration * serviceDurationRate;

  return serviceDurationPrice;
};

const useMaterilasPrice = (): number => {
  const { watch } = useFormContext<FinishOrderFormValues>();

  const products = watch('products');
  const allMaterials = pipe(pluck('materials'), flatten)(products) as CreateOrderMaterialRequest[];
  const materialsSum = calculateTotalPrice(allMaterials);

  return materialsSum;
};

const useFinishOrderPrice = (order: OrderResponse): UseFinishOrderPriceReturn => {
  const { vatTariff } = order;

  const { watch } = useFormContext<FinishOrderFormValues>();
  const { data: paymenthMethods } = useAllPaymentMethods();

  const deliveryPrice = useDeliveryPrice(order);
  const serviceDurationPrice = useServiceDurationPrice(order);
  const materialPrice = useMaterilasPrice();

  const servicePaymentMethodId = watch('servicePaymentMethodId');
  const servicePaymentMethod = paymenthMethods?.find((i) => i.id === servicePaymentMethodId);
  const roundFunction = servicePaymentMethod?.name === 'CASH' ? roundToFull : roundTo2Decimals;

  const finalPrice = roundFunction(serviceDurationPrice + deliveryPrice + materialPrice);

  const vat = vatTariff?.tariffValue ?? 0;
  const finalPriceWithVat = roundFunction((vat * finalPrice) / 100 + finalPrice);

  return {
    finalPriceWithoutVat: finalPrice,
    finalPriceWithVat,
  };
};

export default useFinishOrderPrice;
