import { FC } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import Title from 'src/components/Title';

export type DeactivatedTenantPageProps = {
  //
};

const DeactivatedTenantPage: FC<DeactivatedTenantPageProps> = () => {
  return (
    <div className={clsx('flex flex-col min-h-screen justify-center items-center w-full h-full grow')}>
      <div className='flex items-center'>
        <div className='flex flex-col items-center gap-y-8 max-w-[90vw]'>
          <Title centerLine wideLine className='text-7xl'>
            <FormattedMessage id='app.deactivated_tenant.title' />
          </Title>
          <p className='text-3xl text-center'>
            <FormattedMessage id='app.deactivated_tenant.text' />
          </p>
        </div>
      </div>
    </div>
  );
};

DeactivatedTenantPage.displayName = 'DeactivatedTenantPage';

export default DeactivatedTenantPage;
