import { addDays, compareAsc, parseISO } from 'date-fns';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import useApplyColorScheme from 'src/hooks/useApplyColorScheme';
import { useTenantTitle } from 'src/hooks/useTenantTitle';
import useUserTranslationPack from 'src/hooks/useUserTranslationPack';
import DeactivatedTenantPage from 'src/pages/DeactivatedTenantPage';
import ExpiredUnpaidDemoPage from 'src/pages/ExpiredUnpaidDemoPage';
import TenantNotFoundPage from 'src/pages/TenantNotFoundPage';
import ProtectedRoute from '../components/ProtectedRoute/index';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from '../pages/LoginPage';
import TechnicianOrderFinishPage from '../pages/TechnicianOrderFinishPage';
import TechnicianOrderPage from '../pages/TechnicianOrderPage';
import { AuthProvider } from '../store/authContext';
import { TechnicianAuthProvider } from '../store/technicianAuthContext';
import Loader from 'src/components/utils/Loader';
import { AppRouter } from './AppRouter';
import AdminRouter from './AdminRouter';
import { AdminLayout } from 'src/layouts/AdminLayout';
import { Layout } from 'src/layouts/Layout';
import NoSidebarLayout from 'src/layouts/NoSidebarLayout';
import { AuthorizedLayout } from 'src/layouts/AuthorizedLayout';

const MainRouter: FC = () => {
  const { isLoading: isLoadingTenant, data: tenant } = useCurrentTenant();
  const { isLoading: isLoadingTranslationPack } = useUserTranslationPack();

  useTenantTitle();
  useApplyColorScheme();

  if (isLoadingTenant || isLoadingTranslationPack) return <Loader withoutColor />;
  if (!tenant) return <TenantNotFoundPage />;
  if (tenant.deletedAt) return <DeactivatedTenantPage />;

  const isTrialPeriodExprired = compareAsc(new Date(), addDays(parseISO(tenant.createdAt), 30)) === 1;
  if (tenant.isDemo && isTrialPeriodExprired) return <ExpiredUnpaidDemoPage />;
  return (
    <Routes>
      <Route
        path='/technician-order/*'
        element={
          <TechnicianAuthProvider>
            <NoSidebarLayout>
              <Routes>
                <Route path='/' element={<TechnicianOrderPage />} />
                <Route path='/finish' element={<TechnicianOrderFinishPage />} />
              </Routes>
            </NoSidebarLayout>
          </TechnicianAuthProvider>
        }
      />
      <Route
        path='/*'
        element={
          <AuthProvider>
            <Routes>
              <Route path='/login' element={<LoginPage />} />
              <Route path='/forgot-password' element={<ForgotPasswordPage />} />
              <Route
                path='/*'
                element={
                  <AuthorizedLayout>
                    <AuthProvider>
                      <Routes>
                        <Route
                          path='/admin/*'
                          element={
                            <AdminLayout>
                              <AdminRouter />
                            </AdminLayout>
                          }
                        />

                        <Route
                          path='/*'
                          element={
                            <ProtectedRoute>
                              <Layout>
                                <AppRouter />
                              </Layout>
                            </ProtectedRoute>
                          }
                        />
                      </Routes>
                    </AuthProvider>
                  </AuthorizedLayout>
                }
              />
            </Routes>
          </AuthProvider>
        }
      />
    </Routes>
  );
};

export default MainRouter;
