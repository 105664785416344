import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import HelperText from 'src/components/common/HelperText';
import Logo from 'src/components/common/Logo';
import Textfield from 'src/components/fields/Textfield';
import { ForgotPasswordSchema } from './schema';

function ForgotPasswordPage(): React.ReactElement {
  const client = useApiClient();
  const { handleSubmit, control } = useForm<ForgotPasswordSchema>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<ForgotPasswordSchema> = async (data) => {
    setIsLoading(true);
    try {
      await client.auth.forgottenPassword({
        requestBody: {
          userEmail: data.email.toLocaleLowerCase().trim(),
        },
      });
      toast(<FormattedMessage id='app.toasts.forgotten_password_success' />, {
        type: 'success',
      });
    } catch (error) {
      setError(true);
      toast(<FormattedMessage id='app.toasts.forgotten_password_fail' />, {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='bg-gray-50 grid place-items-center h-screen'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid place-items-center'>
          <div className='flex justify-center items-center w-52 h-28'>
            <Logo className='App-logo' />
          </div>
          <h1 className='text-4xl font-semibold font-openSans text-gray-800 my-10'>
            <FormattedMessage id='app.forgotten_password.forgotten_password' />
          </h1>
          <p className='font-openSans max-w-sm font-thin text-center mb-10'>
            <FormattedMessage id='app.forgotten_password.info' />
          </p>
          <Card className='sm:shadow-none sm:p-4'>
            <div className='flex flex-col mb-10'>
              <div className='text-gray-800 mb-3'>
                <FormattedMessage id='app.common.email' />
              </div>
              <Controller
                control={control}
                name='email'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
              {error && (
                <HelperText variant='error'>
                  <FormattedMessage id='app.forgotten_password.email_not_found' />
                </HelperText>
              )}
            </div>
            <Button className='w-full' disabled={isLoading} type='submit' rounded>
              <FormattedMessage id='app.forgotten_password.reset_password' />
            </Button>
          </Card>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordPage;
