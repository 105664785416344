import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import { usePopupContext } from 'src/components/Popup/hook';

export type NewItemPopupFooterProps = {
  //
};

const NewItemPopupFooter: React.FC<NewItemPopupFooterProps> = () => {
  const { onClose } = usePopupContext();

  return (
    <div className='mt-[2.875rem] flex justify-end gap-x-6 sm:mt-4'>
      <Button size='normal' type='button' rounded variant='secondary' onClick={onClose}>
        <XMarkIcon className='h-8 w-8 mr-1 text-gray' aria-hidden='true' />
        <FormattedMessage id='app.buttons.close' defaultMessage='Zavřít' />
      </Button>
      <Button size='normal' rounded type='submit'>
        <FormattedMessage id='app.buttons.save' defaultMessage='Uložit' />
      </Button>
    </div>
  );
};

export default NewItemPopupFooter;
