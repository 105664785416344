import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { range } from 'ramda';
import { FC, useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { BranchResponse, useApiClient } from 'src/api';
import useContact from 'src/api/hooks/queries/useContact';
import useContactBranches from 'src/api/hooks/queries/useContactBranches';
import Button from 'src/components/Button';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import { PhoneInputLocalizedRhfc } from 'src/components/fields/PhoneInputLocalized';
import { SelectRhfc } from 'src/components/fields/Select';
import { TextFieldRhfc } from 'src/components/fields/Textfield/TextField';
import FormDataRow from 'src/components/FormDataRow';
import { BRANCH_FIELDS } from '../constants';
import { OrderSchema } from '../schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import AutocompleteBe from 'src/components/fields/AutocompleteBe';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import MultiInputBase, { inputDataFactory } from 'src/components/fields/MultiInput';

const ServiceBranchForm: FC<{ disableCreateNew?: boolean }> = ({ disableCreateNew }) => {
  const { data: tenantConfig } = useCurrentTenantConfig();
  const apiClient = useApiClient();
  const { control, setValue, watch, resetField } = useFormContext<OrderSchema>();
  const contactId = watch('company.companyId');
  const { data: contact } = useContact(contactId ?? 0, {
    enabled: !!contactId,
  });

  const createNewCompany = watch('company.createNewCompany');
  const createNewBranch = watch('branch.createNewBranch');
  const { fields: productFields } = useFieldArray({ name: 'orderProducts', control });

  const { data: branches } = useContactBranches(contactId ?? 0, {
    enabled: !!contactId && !tenantConfig?.useBranchSearchInOrderForm,
  });

  const handleBranchChange = async (branchId: number | null): Promise<void> => {
    if (!branchId) return;

    const data = await apiClient.branch.getBranch({ id: branchId });
    BRANCH_FIELDS.forEach((field) => setValue(`branch.branch.${field}`, data[field]));
  };

  const getOptions = async (query: string): Promise<BranchResponse[]> => {
    const data = await apiClient.branch.getBranchesFromSearchQuery({ query, ...DEFAULT_TABLE_PAGINATION });

    return data?.items;
  };

  const handleCreateNewBranchChange = useCallback(
    (value: boolean | null) => {
      if (!value) return;

      resetField('branch.branchId');
      BRANCH_FIELDS.forEach((field) => setValue(`branch.branch.${field}`, ''));
      range(0, productFields.length).forEach((index) => setValue(`orderProducts.${index}.createNewProduct`, true));
    },
    [setValue],
  );

  const handlePrefillFromContact = useCallback(() => {
    try {
      if (!contact) return;
      setValue('branch.branch.name', contact.companyName ?? '');
      setValue('branch.branch.city', contact.city ?? '');
      setValue('branch.branch.street', contact.street ?? '');
      setValue('branch.branch.zip', contact.zip ?? '');
      setValue('branch.branch.vat', contact.in ?? '');
      setValue('branch.branch.email', contact.email ?? '');
      setValue('branch.branch.phoneNumber', contact.phoneNumber ?? '');
    } catch (e) {
      //
      console.error(e);
    }
  }, [contact, setValue]);

  return (
    <div className='flex flex-col mb-16'>
      <div className='font-bold text-gray-800 text-2xl mb-8'>
        <FormattedMessage id='app.order.section_title.branch' />
      </div>

      {!disableCreateNew && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.new_branch' />}>
          <CheckboxRhfc
            control={control}
            name='branch.createNewBranch'
            disabled={!!createNewCompany}
            extraOnChange={handleCreateNewBranchChange}
          />
          {createNewBranch && (
            <Button variant='cta' className='ml-auto' rounded disabled={!contact} onClick={handlePrefillFromContact}>
              <ClipboardDocumentListIcon className='w-6 h-6 mr-1' />
              <FormattedMessage id='app.order.prefill_branch_from_customer' />
            </Button>
          )}
        </FormDataRow>
      )}

      {!createNewBranch && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.branch' />}>
          {tenantConfig?.useBranchSearchInOrderForm ? (
            <Controller
              control={control}
              name='branch.branchId'
              render={({ field }) => (
                <AutocompleteBe
                  {...field}
                  getOptions={getOptions}
                  getOptionLabel={(i) => i.name}
                  getOptionValue={(i) => i.id}
                  extraOnChange={handleBranchChange}
                />
              )}
            />
          ) : (
            <SelectRhfc
              control={control}
              name='branch.branchId'
              options={branches ?? []}
              getOptionLabel={(option) => option.name ?? ''}
              getOptionValue={(option) => option.id}
              extraOnChange={handleBranchChange}
            />
          )}
        </FormDataRow>
      )}
      <FormDataRow asterisk label={<FormattedMessage id='app.branch.name' />}>
        <TextFieldRhfc control={control} name='branch.branch.name' disabled={!createNewBranch} />
      </FormDataRow>

      <FormDataRow asterisk label={<FormattedMessage id='app.common.city' />}>
        <TextFieldRhfc control={control} name='branch.branch.city' disabled={!createNewBranch} />
      </FormDataRow>

      <FormDataRow asterisk label={<FormattedMessage id='app.common.street' />}>
        <TextFieldRhfc control={control} name='branch.branch.street' disabled={!createNewBranch} />
      </FormDataRow>

      <FormDataRow asterisk label={<FormattedMessage id='app.common.zip' />}>
        <TextFieldRhfc control={control} name='branch.branch.zip' disabled={!createNewBranch} />
      </FormDataRow>

      <FormDataRow label={<FormattedMessage id='app.common.branch.ico' />}>
        <TextFieldRhfc control={control} name='branch.branch.vat' disabled={!createNewBranch} />
      </FormDataRow>

      <FormDataRow label={<FormattedMessage id='app.branch.contact_person' />}>
        <TextFieldRhfc control={control} name='branch.branch.contactPerson' disabled={!createNewBranch} />
      </FormDataRow>

      <FormDataRow label={<FormattedMessage id='app.common.phone' />}>
        <PhoneInputLocalizedRhfc control={control} name='branch.branch.phoneNumber' disabled={!createNewBranch} />
      </FormDataRow>

      <Controller
        control={control}
        name='branch.branch.email'
        render={({ field: { value, name, onChange }, fieldState }) => (
          <MultiInputBase<string>
            name={name}
            label={<FormattedMessage id='app.common.email' defaultMessage='Email' />}
            value={value ?? ''}
            onChange={onChange}
            inputsToValue={(inputs) => inputs.map((i) => i.value).join(';')}
            valueToInputs={(value) => value?.split(';').map((v) => inputDataFactory(v)) ?? []}
            FormDataRowProps={{ labelWidthClass: 'w-48' }}
            error={fieldState.error?.message}
          />
        )}
      />
    </div>
  );
};

export default ServiceBranchForm;
