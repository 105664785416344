/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OrderStatusResponse = {
  id: number;
  name: OrderStatusResponse.name;
};
export namespace OrderStatusResponse {
  export enum name {
    PLANNED = 'PLANNED',
    UNSUCCESSFUL = 'UNSUCCESSFUL',
    COMPLETED = 'COMPLETED',
    INVOICED = 'INVOICED',
    ROUTINE = 'ROUTINE',
    DELETED = 'DELETED',
  }
}
