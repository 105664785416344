import clsx from 'clsx';
import React, { HtmlHTMLAttributes, ReactNode } from 'react';

type CardProps = {
  children?: ReactNode;
} & HtmlHTMLAttributes<HTMLDivElement>;

const Card: React.FC<CardProps> = ({ children, className }) => (
  <div
    className={clsx(
      'h-full w-[450px] xs:w-full sm:w-full rounded-md shadow-xl bg-white p-10 sm:shadow-none',
      className,
    )}
  >
    {children}
  </div>
);

export default Card;
