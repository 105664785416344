import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Dispatch, FC, Fragment, SetStateAction, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Title, { TitleProps } from '../Title';
import { PopupContext } from '../Popup/context';
import Button from '../Button';

export type DoublePopupProps = {
  centerTitle?: boolean;
  hideCloseIcon?: boolean;
  className?: string;
  open: boolean;
  onClose: () => void;
  showSecondPanel?: boolean;
  setShowSecondPanel?: Dispatch<SetStateAction<boolean>>;
  leftContent: React.ReactNode;
  leftTitle: React.ReactNode;
  rightContent: React.ReactNode;
  rightTitle: React.ReactNode;
  TitleProps?: TitleProps;
  containerClassName?: string;
};

const DoublePopup: FC<DoublePopupProps> = ({
  containerClassName,
  centerTitle,
  open,
  onClose,
  leftContent,
  leftTitle,
  rightContent,
  rightTitle,
  className,
  hideCloseIcon,
  showSecondPanel,
  setShowSecondPanel,
  TitleProps,
}) => {
  const cancelButtonRef = useRef(null);
  const contextValue = useMemo(() => ({ open, onClose }), [open, onClose]);

  return (
    <PopupContext.Provider value={contextValue}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-[3000]' initialFocus={cancelButtonRef} onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-90'
            leave='ease-in duration-200'
            leaveFrom='opacity-90'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-900 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-50 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 xs:items-center xs:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className={clsx(className, 'flex gap-8')}>
                  <div
                    className={clsx(
                      containerClassName,
                      'relative transform rounded-lg bg-white pl-14 pt-14 pr-10 pb-10 text-left shadow-xl transition-all  max-h-[calc(100vh-2rem)] overflow-auto ' +
                        'sm:my-0 sm:h-screen  sm:p-6 sm:rounded-none sm:max-h-screen xs:max-h-screen xs:my-0 xs:h-screen  xs:p-4 xs:rounded-none',
                    )}
                  >
                    {!hideCloseIcon && (
                      <div className='absolute top-0 right-0 pt-4 pr-4 z-[12000] sm:block'>
                        <button
                          type='button'
                          className='rounded-md bg-gray-50 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                          onClick={onClose}
                        >
                          <span className='sr-only'>
                            <FormattedMessage id='app.buttons.close' />
                          </span>
                          <XMarkIcon className='h-8 w-8 text-gray' aria-hidden='true' />
                        </button>
                      </div>
                    )}
                    <div className='sm:flex sm:items-start xs:flex xs:items-start'>
                      <div className='mt-3 text-left sm:mt-0 sm:text-left xs:mt-0 xs:text-left max-w-full'>
                        {leftTitle && (
                          <Dialog.Title
                            as='div'
                            className={clsx('mb-16 flex', centerTitle ? 'justify-center' : 'justify-between')}
                          >
                            <Title component='h2' className='w-max flex' {...TitleProps}>
                              {leftTitle}
                            </Title>
                            <Button rounded onClick={() => setShowSecondPanel?.((p) => !p)}>
                              <FormattedMessage id='app.button.toggle_calendar' />
                            </Button>
                          </Dialog.Title>
                        )}

                        <div className='mt-2 w-full'>{leftContent}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      containerClassName,
                      !showSecondPanel && 'hidden',
                      'relative transform rounded-lg bg-white pl-14 pt-14 pr-10 pb-10 text-left shadow-xl transition-all  max-h-[calc(100vh-2rem)] overflow-auto ' +
                        'sm:my-0 sm:h-screen  sm:p-6 sm:rounded-none sm:max-h-screen xs:max-h-screen xs:my-0 xs:h-screen  xs:p-4 xs:rounded-none',
                    )}
                  >
                    {!hideCloseIcon && (
                      <div className='absolute top-0 right-0 pt-4 pr-4 z-[12000] sm:block'>
                        <button
                          type='button'
                          className='rounded-md bg-gray-50 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                          onClick={onClose}
                        >
                          <span className='sr-only'>
                            <FormattedMessage id='app.buttons.close' />
                          </span>
                          <XMarkIcon className='h-8 w-8 text-gray' aria-hidden='true' />
                        </button>
                      </div>
                    )}
                    <div className='sm:flex sm:items-start xs:flex xs:items-start'>
                      <div className='mt-3 text-left sm:mt-0 sm:text-left xs:mt-0 xs:text-left max-w-full'>
                        {rightTitle && (
                          <Dialog.Title as='div' className={clsx('mb-16', centerTitle && 'flex justify-center')}>
                            <Title component='h2' className='w-max flex' {...TitleProps}>
                              {rightTitle}
                            </Title>
                          </Dialog.Title>
                        )}

                        <div className='mt-2 w-full'>{rightContent}</div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </PopupContext.Provider>
  );
};

export default DoublePopup;
