import {
  AdjustmentsVerticalIcon,
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  BellAlertIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  DevicePhoneMobileIcon,
  HomeModernIcon,
  IdentificationIcon,
  LanguageIcon,
  NewspaperIcon,
  PaintBrushIcon,
  PuzzlePieceIcon,
  SwatchIcon,
  TruckIcon,
  UserCircleIcon,
  UserIcon,
  WrenchScrewdriverIcon,
  BuildingOffice2Icon,
  EnvelopeOpenIcon,
  ComputerDesktopIcon,
  BanknotesIcon,
  ArrowDownOnSquareStackIcon,
  ArrowUpOnSquareIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import { UserResponse } from 'src/api';
import { NavigationItem } from 'src/types/commonTypes';

export const defaultIsSelectedNavigationItem = (pathname: string, href: string): boolean =>
  pathname === href || pathname.startsWith(`${href}/`);

export const ordersNavigationItem: NavigationItem = {
  renderIcon: (props) => <NewspaperIcon width='1.8rem' height='1.8rem' {...props} />,
  renderText: () => <FormattedMessage id='app.navigation.orders' defaultMessage='Pravidelné servisy' />,
  href: '/order',
  getIsSelected: (pathname, href) => pathname === '/' || pathname.startsWith(href),
  dataJoyride: 'orders',
};

export const contactsNavigationItem: NavigationItem = {
  renderIcon: (props) => <IdentificationIcon width='1.8rem' height='1.8rem' {...props} />,
  renderText: () => <FormattedMessage id='app.navigation.contacts' defaultMessage='Kontakty' />,
  dataJoyride: 'customers',
  href: '/contact',
};

export const branchesNavigationItem: NavigationItem = {
  renderIcon: (props) => <BuildingOffice2Icon width='1.8rem' height='1.8rem' {...props} />,
  renderText: () => <FormattedMessage id='app.navigation.branches' />,
  dataJoyride: 'branches',
  href: '/branch',
};

export const productsNavigationItem: NavigationItem = {
  renderIcon: (props) => <ComputerDesktopIcon width='1.8rem' height='1.8rem' {...props} />,
  renderText: () => <FormattedMessage id='app.navigation.products' />,
  shouldHideOnThisTenant: (tenant) => !tenant.tenantConfig?.config?.enableProductsTab,
  href: '/product',
};

export const calendarNavigationItem: NavigationItem = {
  renderIcon: (props) => <CalendarDaysIcon width='1.8rem' height='1.8rem' {...props} />,
  renderText: () => <FormattedMessage id='app.navigation.calendar' />,
  href: '/calendar',
  dataJoyride: 'calendar',
  allowedRoles: [
    UserResponse.role.ADMINISTRATOR,
    UserResponse.role.SYSTEM_ADMINISTRATOR,
    UserResponse.role.ADMINISTRATOR_TECHNICIAN,
    UserResponse.role.DISPATCHER_TECHNICIAN,
    UserResponse.role.DISPATCHER,
  ],
};

const materialNavigationItem: NavigationItem = {
  renderIcon: (props) => <PuzzlePieceIcon width='1.8rem' height='1.8rem' {...props} />,
  renderText: () => <FormattedMessage id='app.navigation.materials' defaultMessage='Materiál' />,
  href: '/material',
  dataJoyride: 'material',
};

export const technicianAccountNavigationItem: NavigationItem = {
  renderIcon: (props) => <UserCircleIcon width='1.8rem' height='1.8rem' {...props} />,
  renderText: () => <FormattedMessage id='app.navigation.technician_account' />,
  href: '/technician-account',
  dataJoyride: 'technician_profile',
};

export const normalMenuNavigationItems: NavigationItem[] = [
  ordersNavigationItem,
  contactsNavigationItem,
  branchesNavigationItem,
  productsNavigationItem,
  calendarNavigationItem,
  materialNavigationItem,
  technicianAccountNavigationItem,
];

export const mobileBottomNavigationItems: NavigationItem[] = [
  ordersNavigationItem,
  contactsNavigationItem,
  branchesNavigationItem,
  calendarNavigationItem,
  materialNavigationItem,
];

export const adminNavigationItems: NavigationItem[] = [
  {
    renderIcon: (props) => <AdjustmentsVerticalIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.parameters' defaultMessage='Parametry' />,
    href: '/parameter',
    dataJoyride: 'parameters',
    allowedRoles: [
      UserResponse.role.ADMINISTRATOR,
      UserResponse.role.ADMINISTRATOR_TECHNICIAN,
      UserResponse.role.SYSTEM_ADMINISTRATOR,
    ],
  },
  {
    renderIcon: (props) => <DevicePhoneMobileIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.devices' />,
    href: '/devices',
    shouldHideOnThisTenant: (tenant) => !tenant.tenantConfig?.config?.orderForm?.useProductTemplates,
  },
  {
    renderIcon: (props) => <WrenchScrewdriverIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.work_tariffs' />,
    href: '/work-tariffs',
    dataJoyride: 'work_tariffs',
  },
  {
    renderIcon: (props) => <TruckIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.delivery_tariffs' />,
    href: '/delivery-tariffs',
    dataJoyride: 'delivery_tariffs',
  },
  {
    renderIcon: (props) => <BanknotesIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.delivery_rates' />,
    href: '/delivery-rates',
    dataJoyride: 'delivery_rates',
  },
  {
    renderIcon: (props) => <ArrowPathIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.routine_services' />,
    href: '/routine-services',
    dataJoyride: 'routine_services',
  },
  {
    renderIcon: (props) => <CreditCardIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.payment_methods' defaultMessage='Platební metody' />,
    href: '/payment-method',
    dataJoyride: 'payment_methods',
  },
  {
    renderIcon: (props) => <UserIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.users' defaultMessage='Uživatelé' />,
    href: '/user',
    dataJoyride: 'users',
  },
  {
    renderIcon: (props) => <BellAlertIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.notifications' />,
    href: '/notification',
    dataJoyride: 'notifications',
  },
  {
    renderIcon: (props) => <ArrowDownOnSquareStackIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.exports' />,
    href: '/exports',
    dataJoyride: 'exports',
  },
  {
    renderIcon: (props) => <ArrowUpOnSquareIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.imports' />,
    href: '/imports',
    dataJoyride: 'imports',
  },
  {
    renderIcon: (props) => <EnvelopeOpenIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.stockOrders' />,
    href: '/stock-orders',
    shouldHideOnThisTenant: (tenant) => !tenant.tenantConfig?.config?.enableStockOrders,
  },
];

export const settingsNavigationItems: NavigationItem[] = [
  {
    renderIcon: (props) => <UserGroupIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.admin' />,
    href: '/admin',
    dataJoyride: 'admin',
    allowedRoles: [
      UserResponse.role.ADMINISTRATOR,
      UserResponse.role.ADMINISTRATOR_TECHNICIAN,
      UserResponse.role.SYSTEM_ADMINISTRATOR,
    ],
  },
  {
    renderIcon: (props) => <Cog8ToothIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.settings' />,
    href: '/settings',
    dataJoyride: 'settings',
    allowedRoles: [
      UserResponse.role.ADMINISTRATOR,
      UserResponse.role.ADMINISTRATOR_TECHNICIAN,
      UserResponse.role.SYSTEM_ADMINISTRATOR,
    ],
  },
  {
    renderIcon: (props) => <UserIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.profile_settings' />,
    href: '/profile-settings',
    dataJoyride: 'profile-settings',
  },
];

export const systemAdminNavigationItems: NavigationItem[] = [
  {
    renderIcon: (props) => <HomeModernIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.tenants' defaultMessage='Domů' />,
    href: '/tenant',
  },
  {
    renderIcon: (props) => <PaintBrushIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.sysadmin_features' />,
    href: '/sysadmin-features',
  },
  {
    renderIcon: (props) => <LanguageIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.translations' />,
    href: '/translations',
  },
  {
    renderIcon: (props) => <SwatchIcon {...props} width='1.8rem' height='1.8rem' />,
    renderText: () => <FormattedMessage id='app.navigation.order_types' />,
    href: '/types-of-orders',
  },
];

export const logoutNavigationItem: NavigationItem = {
  renderIcon: (props) => <ArrowRightOnRectangleIcon {...props} width='1.8rem' height='1.8rem' />,
  renderText: () => <FormattedMessage id='app.navigation.logout' defaultMessage='Odhlásit' />,
  href: '/login',
};

export const helpNavigationItem: NavigationItem = {
  renderIcon: (props) => <QuestionMarkCircleIcon {...props} width='1.8rem' height='1.8rem' />,
  renderText: () => <FormattedMessage id='app.navigation.help' />,
  shouldHideOnThisTenant: (tenant) => !tenant.tenantConfig?.config?.showHelpButton,
  href: '/',
};

export const devNavigationItems: NavigationItem[] = [];
