import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Popup from 'src/components/Popup';
import { orderFormatNumberToParts, orderFormatPartsToNumber } from './helpers';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { OrderFormatSchema } from './schema';
import FormDataRow from 'src/components/FormDataRow';
import NumberField from 'src/components/fields/NumberField';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import useUpdateOrderTypeNumberFormat from 'src/api/hooks/mutations/orderTypes/useUpdateOrderTypeNumberFormat';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from 'src/components/Button';
import OrderFormatPreview from './OrderFormatPreview';

export type UpdateOrderFormatPopupProps = {
  orderTypeId: number;
  orderNumberFormat: string | null;
  open: boolean;
  onClose: () => void;
};

const UpdateOrderFormatPopup: FC<UpdateOrderFormatPopupProps> = ({ orderTypeId, orderNumberFormat, open, onClose }) => {
  const form = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: zodResolver(OrderFormatSchema),
    defaultValues: orderFormatNumberToParts(orderNumberFormat),
  });
  const { control, handleSubmit, formState } = form;
  const updateMutation = useUpdateOrderTypeNumberFormat();
  const onSubmit = async (data: OrderFormatSchema): Promise<void> => {
    onClose();
    await updateMutation.mutateAsync({ orderTypeId, numberFormat: orderFormatPartsToNumber(data) });
  };
  const values = form.watch();

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={<FormattedMessage id='app.settings.order_type.update_order_format_popup.title' />}
    >
      <form className='flex flex-col w-full h-full' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <FormDataRow label={<FormattedMessage id='app.order_format.prefix' />}>
            <TextFieldRhfc control={control} name='prefix' />
          </FormDataRow>
          <FormDataRow asterisk label={<FormattedMessage id='app.order_format.digits' />}>
            <Controller
              control={control}
              name='digits'
              render={({ field, fieldState }) => <NumberField {...field} error={fieldState.error?.message} />}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.order_format.postfix' />}>
            <TextFieldRhfc control={control} name='postfix' />
          </FormDataRow>
          {formState.isValid && <OrderFormatPreview parts={values} />}
          <div className='mt-[2.875rem] flex justify-end gap-x-6 sm:mt-4'>
            <Button size='normal' type='button' rounded variant='secondary' onClick={onClose}>
              <XMarkIcon className='h-8 w-8 mr-1 text-gray' aria-hidden='true' />
              <FormattedMessage id='app.buttons.close' defaultMessage='Zavřít' />
            </Button>
            <Button size='normal' rounded type='submit'>
              <FormattedMessage id='app.buttons.save' defaultMessage='Uložit' />
            </Button>
          </div>
        </FormProvider>
      </form>
    </Popup>
  );
};

export default UpdateOrderFormatPopup;
