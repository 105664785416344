import clsx from 'clsx';
import { mergeDeepRight } from 'ramda';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { HeadquartersResponse, OrderTypeListItemRepsonse, TenantConfigDto, VatTariffResponse } from 'src/api';
import useUpdateTenantConfig from 'src/api/hooks/mutations/tenantConfig/useUpdateTenantConfig';
import Button from 'src/components/Button';
import TenantConfigurationGeneral from './parts/TenantConfigurationGeneral';
import TenantConfigurationOrderForm from './parts/TenantConfigurationOrderForm';
import TenantConfigurationOrderTable from './parts/TenantConfigurationOrderTable';
import TenantConfigurationProductForm from './parts/TenantConfigurationProductForm';
import { TenantConfigSchema } from './schema';
import TenantConfigurationContactForm from './parts/TenantConfigurationContactForm';
import TenantConfigurationMaterialTable from './parts/TenantConfigurationMaterialTable';
import { zodResolver } from '@hookform/resolvers/zod';

export type TenantConfigurationFormProps = {
  className?: string;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  config?: TenantConfigDto;
  tenantId: number;
};

const defaultValues: Partial<TenantConfigDto> = {
  orderForm: {
    useProductTemplates: false,
    defaultValues: {},
  },
  orderTable: {
    defaultColumns: [
      'orderNumber',
      'company.companyName',
      'productName',
      'defectDescription',
      'technicians.profile.name',
      'datetime',
      'status.id',
      'actions',
    ],
  },
  allowB2C: false,
};

const createDefaultValues = (config?: TenantConfigDto): TenantConfigDto => mergeDeepRight(defaultValues, config ?? {});

const TenantConfigurationForm: FC<TenantConfigurationFormProps> = ({ className, config, tenantId }) => {
  const updateTenantConfig = useUpdateTenantConfig();
  const form = useForm<TenantConfigSchema>({
    defaultValues: createDefaultValues(config) as typeof defaultValues,
    resolver: zodResolver(TenantConfigSchema),
  });
  const onSubmit: SubmitHandler<TenantConfigSchema> = (data) => {
    updateTenantConfig.mutate({ tenantId, requestBody: data });
  };
  const { handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form
        className={clsx(className, 'flex flex-col gap-y-4 font-semibold')}
        onSubmit={handleSubmit(onSubmit, console.error)}
      >
        <TenantConfigurationGeneral />
        <TenantConfigurationOrderForm />
        <TenantConfigurationOrderTable />
        <TenantConfigurationProductForm />
        <TenantConfigurationMaterialTable />
        <TenantConfigurationContactForm />

        <Button type='submit' className='max-w-xs' rounded>
          <FormattedMessage id='app.buttons.save' />
        </Button>
      </form>
    </FormProvider>
  );
};

TenantConfigurationForm.displayName = 'TenantConfigurationForm';

export default TenantConfigurationForm;
