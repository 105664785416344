import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrderResponse } from 'src/api';
import useAllWorkTariffs from 'src/api/hooks/queries/useAllWorkTariffs';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { SelectRhfc } from 'src/components/fields/Select';
import FieldWrapper from '../../FieldWrapper';
import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import { FinishOrderFormValues } from '../schema';

export type WorkFormProps = {
  order: OrderResponse;
  hiddenFields?: string[];
};

const WorkForm: FC<WorkFormProps> = ({ order, hiddenFields }) => {
  const intl = useIntl();
  const { data: workTariffs } = useAllWorkTariffs();
  const { control } = useFormContext<FinishOrderFormValues>();
  const useManualServiceDurationPrice = useWatch<FinishOrderFormValues>({ name: 'useManualServiceDurationPrice' });

  return (
    <>
      <SectionTitle className='mb-4'>
        <FormattedMessage id='app.finish_order.section_title.work' />
      </SectionTitle>
      {order?.orderType?.slConfig?.allowManualPrices && (
        <Section noMb={!useManualServiceDurationPrice}>
          <div className='flex gap-x-4 min-h-[4.75rem]'>
            <CheckboxRhfc control={control} name='useManualServiceDurationPrice' />
            <FormattedMessage id='app.finish_order.manual_work_price' />
          </div>
          {useManualServiceDurationPrice && (
            <FieldWrapper label={<FormattedMessage id='app.finish_order.manual_work_price' />}>
              <NumberFieldRhfc control={control} name='serviceDurationPrice' />
            </FieldWrapper>
          )}
        </Section>
      )}

      {!useManualServiceDurationPrice && (
        <Section>
          <FieldWrapper label={<FormattedMessage id='app.finish_order.work_tariff' />}>
            <SelectRhfc
              control={control}
              name='workTariffId'
              options={[
                { id: null, name: intl.formatMessage({ id: 'app.tenant_default_work_tariff' }) },
                ...(workTariffs ?? []),
              ]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </FieldWrapper>
          {!hiddenFields?.includes('serviceDuration') && (
            <FieldWrapper label={<FormattedMessage id='app.finish_order.work_duration' />}>
              <NumberFieldRhfc control={control} name='serviceDuration' />
            </FieldWrapper>
          )}
        </Section>
      )}
    </>
  );
};

export default WorkForm;
