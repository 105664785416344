import { FC } from 'react';
import clsx from 'clsx';
import FormDataRow from 'src/components/FormDataRow';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import { SelectRhfc } from 'src/components/fields/Select';
import { SelectFieldSchemaResponse, UserResponse } from 'src/api';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { FormBuilderFieldSchema } from './schema';
import MultiInputBase, { inputDataFactory } from 'src/components/fields/MultiInput';
import { FormattedMessage } from 'react-intl';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import { zodResolver } from '@hookform/resolvers/zod';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';

export type FormBuilderFieldFormProps = {
  className?: string;
  onSubmit: SubmitHandler<FormBuilderFieldSchema>;
  defaultValues?: Partial<FormBuilderFieldSchema>;
};

const DEFAULT_VALUES = {
  id: '',
  name: '',
  label: '',
  type: SelectFieldSchemaResponse.type.CHECKBOX,
  options: null,
  showIfName: null,
} as unknown as FormBuilderFieldSchema;

const FormBuilderFieldForm: FC<FormBuilderFieldFormProps> = ({ className, onSubmit, defaultValues }) => {
  const { data: currentUser } = useCurrentUser();
  const form = useForm<FormBuilderFieldSchema>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
    resolver: zodResolver(FormBuilderFieldSchema),
  });
  const { control, watch, setValue, handleSubmit } = form;

  const type = watch('type');
  const isSysmAdmin = currentUser?.role === UserResponse.role.SYSTEM_ADMINISTRATOR;

  return (
    <form className={clsx(className)} onSubmit={handleSubmit(onSubmit)}>
      {isSysmAdmin && (
        <FormDataRow label={<FormattedMessage id='app.common.name' />}>
          <TextFieldRhfc control={control} name='name' />
        </FormDataRow>
      )}
      <FormDataRow label={<FormattedMessage id='app.common.label' />}>
        <TextFieldRhfc control={control} name='label' />
      </FormDataRow>
      <FormDataRow label={<FormattedMessage id='app.common.type' />}>
        <SelectRhfc
          control={control}
          extraOnChange={(value) => {
            if (value !== SelectFieldSchemaResponse.type.SELECT) setValue('options', null);
            else setValue('options', ['']);
          }}
          name='type'
          options={Object.values(SelectFieldSchemaResponse.type)}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
        />
      </FormDataRow>

      <FormDataRow label={<FormattedMessage id='app.order_type.sl_config.field.ignoreFalseInPDF' />}>
        <CheckboxRhfc control={control} name='ignoreFalseInPDF' />
      </FormDataRow>

      {type === SelectFieldSchemaResponse.type.SELECT && (
        <Controller
          name='options'
          control={control}
          render={({ field: { value, onChange } }) => (
            <MultiInputBase<string[]>
              name='options'
              label={<FormattedMessage id='app.order_type.sl_config.field.options' />}
              value={value ?? []}
              onChange={onChange}
              inputsToValue={(inputs) => inputs.map((i) => i.value)}
              valueToInputs={(value) => value.map(inputDataFactory) ?? []}
              FormDataRowProps={{
                labelWidthClass: 'w-48',
              }}
            />
          )}
        />
      )}

      <NewItemPopupFooter />
    </form>
  );
};

FormBuilderFieldForm.displayName = 'FormBuilderFieldForm';

export default FormBuilderFieldForm;
