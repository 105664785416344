import { FC } from 'react';
import clsx from 'clsx';
import ParametersRow from 'src/components/common/ParametersRow';
import { FormattedMessage } from 'react-intl';
import { StockOrderResponse } from 'src/api';
import HyperLink from 'src/components/common/HyperLink';
import { LinkIcon } from '@heroicons/react/24/outline';

export type StockOrderPreviewProps = {
  data: StockOrderResponse;
  className?: string;
  noTitle?: boolean;
};

const StockOrderPreview: FC<StockOrderPreviewProps> = ({ data, noTitle, className }) => {
  return (
    <div className={clsx('flex flex-col w-full', className)}>
      {!noTitle && (
        <ParametersRow.Title>
          <FormattedMessage id='app.stock_order_detail_page.title' values={{ value: data.id }} />
        </ParametersRow.Title>
      )}
      {data.company && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.stock_order.customer' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <span>{data?.company?.companyName}</span>
            <HyperLink href={`/contact/${data.companyId}`}>
              <LinkIcon className='ml-3 w-7' />
            </HyperLink>
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {data.product && (
        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.stock_order.product_name' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <span>{data?.product?.productName}</span>
            <HyperLink href={`/product/${data.productId}`}>
              <LinkIcon className='ml-3 w-7' />
            </HyperLink>
          </ParametersRow.Value>
        </ParametersRow>
      )}
      {data.stockOrderMaterials?.map((stockOrderMaterial) => (
        <ParametersRow key={stockOrderMaterial.id}>
          <ParametersRow.Label>
            <FormattedMessage id='app.stock_order.material' />
          </ParametersRow.Label>
          <ParametersRow.Value>
            <span>{`${stockOrderMaterial?.material?.materialName} (${stockOrderMaterial?.quantity})`}</span>
            {stockOrderMaterial.material && (
              <HyperLink href={`/material/${stockOrderMaterial.material.id}`}>
                <LinkIcon className='ml-3 w-7' />
              </HyperLink>
            )}
          </ParametersRow.Value>
        </ParametersRow>
      ))}
      <ParametersRow>
        <ParametersRow.Label>
          <FormattedMessage id='app.stock_order.text' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data.text}</ParametersRow.Value>
      </ParametersRow>
    </div>
  );
};

StockOrderPreview.displayName = 'StockOrderPreview';

export default StockOrderPreview;
