import clsx from 'clsx';
import React from 'react';
import MobileBottomNav from 'src/components/MobileBottomNav';
import MobileMenu from 'src/components/MobileMenu';
import SidePanel from 'src/components/SidePanel';
import { isMdOrBigger } from 'src/helpers/screenSize';
import useWindowWidth from 'src/hooks/useWindowWidth';

type AuthorizedLayoutProps = {
  children: React.ReactNode;
};

export const AuthorizedLayout: React.FC<AuthorizedLayoutProps> = ({ children }) => {
  const width = useWindowWidth();
  const isDesktop = isMdOrBigger(width);

  return (
    <div className={clsx('relative w-dvw h-dvh flex', !isDesktop && 'flex-col')}>
      {isDesktop ? <SidePanel /> : <MobileMenu />}
      <div className={clsx('flex flex-col overflow-y-scroll w-full h-full bg-white')}>{children}</div>
      {!isDesktop && <MobileBottomNav />}
    </div>
  );
};
