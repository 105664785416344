import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Title from 'src/components/Title';
import SadWorkerImg from 'src/pictures/sad-worker.png';

export type TenantNotFoundPageProps = {
  className?: string;
};

const TenantNotFoundPage: FC<TenantNotFoundPageProps> = ({ className }) => {
  return (
    <div className={clsx(className, 'flex flex-col min-h-screen justify-center items-center w-full h-full grow')}>
      <div className='flex items-center'>
        <div className='flex flex-col items-center gap-y-8 max-w-[420px]'>
          <Title centerLine wideLine className='text-7xl'>
            <FormattedMessage id='app.tenant_not_found_page.title' />
          </Title>
          <p className='text-3xl text-center'>
            <FormattedMessage id='app.error_page.404.description' />
          </p>
        </div>
        <div className='md:hidden xs:hidden sm:hidden max-w-xl'>
          <img src={SadWorkerImg} alt='sad worker' />
        </div>
      </div>
    </div>
  );
};

TenantNotFoundPage.displayName = 'TenantNotFoundPage';

export default TenantNotFoundPage;
