import { FC } from 'react';
import clsx from 'clsx';
import ExportCard, { ExportCardData } from '../ExportCard';

export type ExportCardListProps = {
  className?: string;
  items: ExportCardData[];
};

const ExportCardList: FC<ExportCardListProps> = ({ className, items }) => {
  return (
    <ul
      role='list'
      className={clsx(
        'grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-x-6 gap-y-8 xl:gap-x-8',
        className,
      )}
    >
      {items.map((item, index) => (
        <ExportCard key={index} {...item} />
      ))}
    </ul>
  );
};

ExportCardList.displayName = 'ExportCardList';

export default ExportCardList;
