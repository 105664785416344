import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderStatusResponse } from 'src/api';

const getClassNamesByName = (name: string): string => {
  switch (name) {
    case OrderStatusResponse.name.PLANNED:
      return 'bg-status-planned';
    case OrderStatusResponse.name.INVOICED:
      return 'bg-status-paid';
    case OrderStatusResponse.name.COMPLETED:
      return 'bg-status-success';
    case OrderStatusResponse.name.UNSUCCESSFUL:
      return 'bg-status-failed';
    case OrderStatusResponse.name.ROUTINE:
      return 'bg-status-routine';
    case OrderStatusResponse.name.DELETED:
      return 'bg-status-deleted';
    default:
      return '';
  }
};

export type StatusLabelProps = {
  name: OrderStatusResponse.name;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ name }) => {
  return (
    <span
      className={clsx(
        'flex items-center justify-center w-44 h-[36px] py-2 rounded-full text-white text-sm font-semibold',
        getClassNamesByName(name),
      )}
    >
      <FormattedMessage id={`app.order_status.${name?.toLowerCase() as Lowercase<OrderStatusResponse.name>}`} />
    </span>
  );
};

export default StatusLabel;
