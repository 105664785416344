/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateTenantRequest = {
  /**
   * e.g. demo
   */
  tenantName: string;
  /**
   * e.g. demo.pocketservice.app
   */
  tenantDomain: string;
  applicationTitle: string | null;
  colorTheme?: CreateTenantRequest.colorTheme | null;
  maxUserCount: number;
  isDemo: boolean;
};
export namespace CreateTenantRequest {
  export enum colorTheme {
    ORANGE = 'orange',
    PURPLE = 'purple',
    BLUE = 'blue',
    GREEN = 'green',
    RED = 'red',
    YELLOW = 'yellow',
  }
}
