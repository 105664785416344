import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import useUpdateResetCurrentTenantConfig from 'src/api/hooks/mutations/tenant/useUpdateResetCurrentTenantConfig';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import Button from 'src/components/Button';
import ParametersRow from 'src/components/common/ParametersRow';
import Loader from 'src/components/utils/Loader';
import TenantConfigFieldToggle from './TenantConfigFieldToggle';
import { MessageId } from 'src/types/commonTypes';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useShowInfoPopup } from 'src/hooks/popups/useShowInfoPopup';

const fields: Array<{ title: MessageId; hint: MessageId; name: string }> = [
  {
    title: 'app.tenants_page.configuration.order_form.allow_b2c',
    hint: 'app.tenants_page.configuration.order_form.allow_b2c.hint',
    name: 'allowB2C',
  },
  {
    title: 'app.tenants_page.configuration.disable_vat',
    hint: 'app.tenants_page.configuration.disable_vat.hint',
    name: 'disableVat',
  },
  {
    title: 'app.tenants_page.configuration.order_form.allow_overwrite_email_in_sl',
    hint: 'app.tenants_page.configuration.order_form.allow_overwrite_email_in_sl.hint',
    name: 'allowOverwriteEmailInSL',
  },
  {
    title: 'app.tenants_page.configuration.enable_stock_orders',
    hint: 'app.tenants_page.configuration.enable_stock_orders.hint',
    name: 'enableStockOrders',
  },
  {
    title: 'app.tenants_page.configuration.enable_products_tab',
    hint: 'app.tenants_page.configuration.enable_products_tab.hint',
    name: 'enableProductsTab',
  },
  {
    title: 'app.tenants_page.configuration.order_form.use_product_templates',
    hint: 'app.tenants_page.configuration.order_form.use_product_templates.hint',
    name: 'orderForm.useProductTemplates',
  },
  {
    title: 'app.tenants_page.configuration.show_warranty',
    hint: 'app.tenants_page.configuration.show_warranty.hint',
    name: 'productForm.showWarranty',
  },
  {
    title: 'app.tenants_page.configuration.informative_material_suggetions',
    hint: 'app.tenants_page.configuration.informative_material_suggetions.hint',
    name: 'materialTable.informativeMaterialSuggetions',
  },
  {
    title: 'app.tenants_page.configuration.order_form.allow_absent_customer',
    hint: 'app.tenants_page.configuration.order_form.allow_absent_customer.hint',
    name: 'allowAbsentCustomer',
  },
  {
    title: 'app.tenants_page.configuration.order_form.default_show_calendar',
    hint: 'app.tenants_page.configuration.order_form.default_show_calendar.hint',
    name: 'defaultShowCalendarInOrderForm',
  },
];

export type TenantConfigSettingsFormProps = {
  className?: string;
};

const TenantConfigSettingsForm: React.FC<TenantConfigSettingsFormProps> = ({ className }) => {
  const intl = useIntl();
  const updateResetCurrentTenantConfig = useUpdateResetCurrentTenantConfig();
  const { isLoading: isLoadingCurrentTenant } = useCurrentTenant();
  const showInfoPopup = useShowInfoPopup();

  if (isLoadingCurrentTenant) return <Loader />;

  return (
    <div className={clsx('flex flex-col', className)}>
      <ParametersRow.Title className='flex justify-between items-start'>
        <FormattedMessage id='app.settings.tenant_config.title' />
        <Button variant='primary' rounded size='small' onClick={() => updateResetCurrentTenantConfig.mutate()}>
          <FormattedMessage id='app.button.reset' />
        </Button>
      </ParametersRow.Title>

      {fields.map(({ name, title, hint }, index) => (
        <ParametersRow key={index}>
          <ParametersRow.Label>
            <Button
              variant='cta'
              size='small'
              onClick={() =>
                showInfoPopup({
                  title: intl.formatMessage({ id: title }),
                  content: intl.formatMessage({ id: hint }),
                })
              }
            >
              <InformationCircleIcon className='w-6 h-6 text-primary' />
            </Button>
            <FormattedMessage id={title} />
          </ParametersRow.Label>
          <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
            <TenantConfigFieldToggle name={name} />
          </ParametersRow.Value>
        </ParametersRow>
      ))}
    </div>
  );
};

export default TenantConfigSettingsForm;
