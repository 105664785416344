import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import stub from 'src/utils/stub';

type PaginationButtonProps = {
  className?: string;
  onClick: () => void;
  disabled: boolean;
  text: ReactNode | JSX.Element;
  icon: ReactNode | JSX.Element;
};

const PaginationButton: FC<PaginationButtonProps> = ({ className, onClick, icon, text, disabled }) => (
  <button
    onClick={disabled ? stub : onClick}
    className={clsx(
      className,
      'flex items-center gap-x-1 h-6 w-24 sm:w-auto xs:w-auto',
      disabled && 'text-gray-300 cursor-not-allowed',
    )}
    aria-label='prev'
  >
    {icon}
    <span className='sm:hidden xs:hidden'>{text}</span>
  </button>
);

export default PaginationButton;
