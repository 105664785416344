import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TenantConfigDto } from 'src/api';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';

const TenantConfigurationGeneral: FC = () => {
  const { control, setValue } = useFormContext<TenantConfigDto>();

  return (
    <>
      <span className='text-2xl mb-4'>
        <FormattedMessage id='app.tenants_page.configuration.general' />
      </span>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.allow_b2c' />
            <CheckboxRhfc
              control={control}
              name='allowB2C'
              extraOnChange={(value) => {
                if (!value) setValue('orderForm.defaultValues.category', 'B2B');
              }}
            />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.allow_b2c.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.use_branch_search' />
            <CheckboxRhfc control={control} name='useBranchSearchInOrderForm' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.use_branch_search.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.show_custom_number_field_in_pdf' />
            <CheckboxRhfc control={control} name='showCustomNumberFieldInPDF' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.show_custom_number_field_in_pdf.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.allow_absent_customer' />
            <CheckboxRhfc control={control} name='allowAbsentCustomer' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.allow_absent_customer.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.allow_overwrite_email_in_sl' />
            <CheckboxRhfc control={control} name='allowOverwriteEmailInSL' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.allow_overwrite_email_in_sl.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.disable_vat' />
            <CheckboxRhfc control={control} name='disableVat' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.disable_vat.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.enable_stock_orders' />
            <CheckboxRhfc control={control} name='enableStockOrders' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.enable_stock_orders.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.show_help_button' />
            <CheckboxRhfc control={control} name='showHelpButton' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.show_help_button.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.enable_contact_search_by_company_in' />
            <CheckboxRhfc control={control} name='enableContactSearchByCompanyIn' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.enable_contact_search_by_company_in.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.enable_contact_search_by_product_code' />
            <CheckboxRhfc control={control} name='enableContactSearchByProductCode' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.enable_contact_search_by_product_code.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.enable_products_tab' />
            <CheckboxRhfc control={control} name='enableProductsTab' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.enable_products_tab.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.enable_table_search_for_branches' />
            <CheckboxRhfc control={control} name='enableTableSearchForBranches' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.enable_table_search_for_branches.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.default_show_calendar' />
            <CheckboxRhfc control={control} name='defaultShowCalendarInOrderForm' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.default_show_calendar.hint' />
          </span>
        </details>
      </div>
      <div className='flex w-full gap-3 items-center mb-8'>
        <details className='flex w-full gap-3'>
          <summary className='flex w-full gap-3'>
            <InformationCircleIcon className='w-6 h-6 text-primary' />
            <FormattedMessage id='app.tenants_page.configuration.order_form.show_customer_contact_in_pdf' />
            <CheckboxRhfc control={control} name='showCustomerContactsInPDF' />
          </summary>
          <span className='mt-2'>
            <FormattedMessage id='app.tenants_page.configuration.order_form.show_customer_contact_in_pdf.hint' />
          </span>
        </details>
      </div>
    </>
  );
};

export default TenantConfigurationGeneral;
