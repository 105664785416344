import clsx from 'clsx';
import { FC, memo } from 'react';
import invertColor from 'src/helpers/invertColor';
import stringToHexColor from 'src/helpers/stringToColor';

export type ColorAvatarProps = {
  name: string;
  className?: string;
};

const ColorAvatar: FC<ColorAvatarProps> = ({ name, className }) => {
  const backgroundColor = stringToHexColor(name);
  const color = invertColor(backgroundColor);
  const letters = name
    .replace(/[^a-zA-Z ]/g, '')
    .split(' ')
    .filter(Boolean)
    .slice(0, 2)
    .map((word) => word[0].toUpperCase())
    .join('');

  return (
    <div
      className={clsx('flex items-center justify-center w-8 h-8 min-w-8 min-h-8', className)}
      style={{ backgroundColor }}
    >
      <span className='text-xs font-bold' style={{ color }}>
        {letters}
      </span>
    </div>
  );
};

export default memo(ColorAvatar);
