import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { defaultIsSelectedNavigationItem } from 'src/constants/navigation';

const adminMenuItems = [
  {
    renderText: () => <FormattedMessage id='app.navigation.parameters' />,
    href: '/admin/parameter',
    dataJoyride: 'parameters',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.work_tariffs' />,
    href: '/admin/work-tariffs',
    dataJoyride: 'work_tariffs',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.delivery_tariffs' />,
    href: '/admin/delivery-tariffs',
    dataJoyride: 'delivery_tariffs',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.delivery_rates' />,
    href: '/admin/delivery-rates',
    dataJoyride: 'delivery_rates',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.routine_services' />,
    href: '/admin/routine-services',
    dataJoyride: 'routine_services',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.payment_methods' />,
    href: '/admin/payment-method',
    dataJoyride: 'payment_methods',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.users' />,
    href: '/admin/user',
    dataJoyride: 'users',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.notifications' />,
    href: '/admin/notification',
    dataJoyride: 'notifications',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.exports' />,
    href: '/admin/exports',
    dataJoyride: 'exports',
  },
  {
    renderText: () => <FormattedMessage id='app.navigation.imports' />,
    href: '/admin/imports',
    dataJoyride: 'imports',
  },
];

const AdministrationPage: FC = () => {
  const { pathname } = useLocation();

  return (
    <>
      <nav className='flex flex-col w-full p-4 md:p-8 bg-gray-50'>
        <h1 className='font-bold text-3xl mb-16'>
          <FormattedMessage id='app.administration.page_title' />
        </h1>
        <ul className='flex gap-x-6 overflow-x-auto'>
          {adminMenuItems.map((item, index) => (
            <li key={index} className='flex flex-col gap-y-2'>
              <Link
                to={item.href}
                className={clsx(
                  'whitespace-nowrap',
                  defaultIsSelectedNavigationItem(pathname, item.href) && 'font-semibold',
                )}
              >
                {item.renderText()}
              </Link>
              <span
                className={clsx(
                  'block h-1 w-full rounded',
                  defaultIsSelectedNavigationItem(pathname, item.href) && 'bg-primary',
                )}
              ></span>
            </li>
          ))}
        </ul>
      </nav>
      <main className='w-full h-full flex flex-col p-4 md:p-8 sm:p-3'>
        <Outlet />
      </main>
    </>
  );
};

export default AdministrationPage;
