import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Title from 'src/components/Title';
import TenantInfo from './components/TenantInfo';
import TenantOrderDefaultValuesForm from './components/TenantOrderDefaultValuesForm';
import TenantConfigSettingsForm from './components/TenantConfigSettingsForm';
import useAllOrderTypes from 'src/api/hooks/queries/useAllOrderTypes';
import OrderTypeSettingsFormContainer from './components/OrderTypeSettingsFormContainer';

const SettingsPage: FC = () => {
  const { data: allOrderTypes } = useAllOrderTypes();

  return (
    <div className='flex flex-col w-full gap-16'>
      <div className='flex justify-between'>
        <Title className='pb-3'>
          <FormattedMessage id='app.settings_page.title' />
        </Title>
      </div>
      <div className='grid gap-16 grid-cols-1 xl:grid-cols-2'>
        <TenantInfo />
        <TenantOrderDefaultValuesForm />
      </div>
      <div className='w-full xl:w-1/2'>
        <TenantConfigSettingsForm />
      </div>
      <Title noLine component='h2'>
        <FormattedMessage id='app.settings.order_types.title' />
      </Title>
      <div className='w-full grid gap-16 grid-cols-1 xl:grid-cols-2'>
        {allOrderTypes?.map((orderType) => (
          <OrderTypeSettingsFormContainer key={orderType.id} name={orderType.name} orderTypeId={orderType.id} />
        ))}
      </div>
    </div>
  );
};

export default SettingsPage;
