import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { CreateHeadquatersRequest } from 'src/api';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HeadquartersSchema } from './schema';
import { FormattedMessage } from 'react-intl';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import { SelectRhfc } from 'src/components/fields/Select';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import MultiInputBase, { inputDataFactory } from 'src/components/fields/MultiInput';
import { zodResolver } from '@hookform/resolvers/zod';

export type HeadquatersFormProps = {
  className?: string;
  defaultValues?: Partial<CreateHeadquatersRequest>;
  onSubmit: SubmitHandler<CreateHeadquatersRequest>;
};

const HeadquatersForm: FC<HeadquatersFormProps> = ({ className, defaultValues, onSubmit }) => {
  const { data } = useAllVatTariffs();

  const form = useForm<HeadquartersSchema>({
    resolver: zodResolver(HeadquartersSchema),
    defaultValues,
  });

  const { handleSubmit, control, setValue, register } = form;

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setValue('stampFile', e.target.files?.[0]),
    [setValue],
  );

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof CreateHeadquatersRequest, v));
  }, [defaultValues]);

  return (
    <div className={clsx('flex-col w-full', className)}>
      <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.service_duration_rate' />}>
            <NumberFieldRhfc control={control} name='serviceDurationRate' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.service_mileage_rate' />}>
            <NumberFieldRhfc control={control} name='serviceMileageRate' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.service_time_on_road_rate' />}>
            <NumberFieldRhfc control={control} name='serviceTimeOnRoadRate' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.company_name' />}>
            <TextFieldRhfc control={control} name='companyName' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.company_city' />}>
            <TextFieldRhfc control={control} name='companyCity' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.company_street' />}>
            <TextFieldRhfc control={control} name='companyStreet' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.company_zip' />}>
            <TextFieldRhfc control={control} name='companyZIP' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.parameter_title.IC' />}>
            <TextFieldRhfc control={control} name='companyIn' />
          </FormDataRow>

          <FormDataRow label={<FormattedMessage id='app.common.vat' />}>
            <TextFieldRhfc control={control} name='companyVatNumber' />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.vat_tariff.value' />}>
            <SelectRhfc
              control={control}
              name='defaultVatTariffId'
              options={data ?? []}
              getOptionLabel={(option) => `${option.tariffValue}%`}
              getOptionValue={(option) => option.id}
            />
          </FormDataRow>

          <Controller
            name='accountingEmail'
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultiInputBase<string>
                name='accountingEmail'
                label={<FormattedMessage id='app.tenant.accounting_email' />}
                value={value ?? ''}
                onChange={onChange}
                inputsToValue={(inputs) => inputs.map((i) => i.value).join(';')}
                valueToInputs={(value) => value?.split(';').map((v) => inputDataFactory(v)) ?? []}
                FormDataRowProps={{
                  labelWidthClass: 'w-48',
                  asterisk: true,
                }}
              />
            )}
          />
          <FormDataRow label={<FormattedMessage id='app.headquarters.stamp' />}>
            <input
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ref={register('stampFile') as any}
              onChange={handleFileChange}
              type='file'
              accept='image/*'
            />
          </FormDataRow>
        </FormProvider>
        <NewItemPopupFooter />
      </form>
    </div>
  );
};

HeadquatersForm.displayName = 'HeadquatersForm';

export default HeadquatersForm;
