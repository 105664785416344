import clsx from 'clsx';
import { PencilIcon } from '@heroicons/react/24/solid';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'src/components/Button';
import ParametersRow from 'src/components/common/ParametersRow';
import OrderTypeFieldToggle from './OrderTypeFieldToggle';
import { MessageId } from 'src/types/commonTypes';
import { useShowInfoPopup } from 'src/hooks/popups/useShowInfoPopup';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import useResetOrderTypeConfig from 'src/api/hooks/mutations/orderTypes/useResetOrderTypeConfig';
import UpdateOrderTypeNamePopup from './UpdateOrderTypeNamePopup';
import UpdateOrderFormatPopup from './UpdateOrderFormatPopup';
import { UseFormBuilderStateOptionsValue } from 'src/components/FormBuilder/context';
import { formBuilderDtoToState, formBuilderStateToDto } from 'src/components/FormBuilder/helpers';
import { OrderTypeRepsonse } from 'src/api';
import FormBuilderContainer from 'src/components/FormBuilder/FormBuilderContainer';
import useUpdateOrderTypeConfigField from 'src/api/hooks/mutations/orderTypes/useUpdateOrderTypeConfigField';

const fields: Array<{ title: MessageId; hint: MessageId; name: string }> = [
  {
    title: 'app.order.order_type.configuration.show_branch_in_header',
    hint: 'app.order.order_type.configuration.show_branch_in_header.hint',
    name: 'showBranchInHeader',
  },
  {
    title: 'app.tenants_page.configuration.order_form.show_custom_number_field_in_pdf',
    hint: 'app.tenants_page.configuration.order_form.show_custom_number_field_in_pdf.hint',
    name: 'showCustomNumberFieldInPDF',
  },
  {
    title: 'app.order.order_type.configuration.manual_prices',
    hint: 'app.order.order_type.configuration.manual_prices.hint',
    name: 'allowManualPrices',
  },
  {
    title: 'app.order.order_type.configuration.hide_material_prices',
    hint: 'app.order.order_type.configuration.hide_material_prices.hint',
    name: 'hidePricesForMaterilas',
  },
  {
    title: 'app.order.order_type.configuration.hide_delivery_prices',
    hint: 'app.order.order_type.configuration.hide_delivery_prices.hint',
    name: 'hidePricesForDelivery',
  },
  {
    title: 'app.order.order_type.configuration.hide_delivery_section',
    hint: 'app.order.order_type.configuration.hide_delivery_section.hint',
    name: 'hideDeliverySection',
  },
  {
    title: 'app.order.order_type.configuration.hide_payment_section',
    hint: 'app.order.order_type.configuration.hide_payment_section.hint',
    name: 'hidePaymentSection',
  },
  {
    title: 'app.order.order_type.configuration.hide_work_section',
    hint: 'app.order.order_type.configuration.hide_work_section.hint',
    name: 'hideWorkSection',
  },
];

export type OrderTypeSettingsFormProps = {
  className?: string;
  name?: string;
  orderTypeId: number;
  data: OrderTypeRepsonse;
};

const OrderTypeSettingsForm: FC<OrderTypeSettingsFormProps> = ({ className, data, orderTypeId, name }) => {
  const intl = useIntl();
  const showInfoPopup = useShowInfoPopup();
  const resetMutation = useResetOrderTypeConfig();
  const updateField = useUpdateOrderTypeConfigField();
  const [openRenamePopup, setOpenRenamePopup] = useState(false);
  const [openNumberFormatPopup, setOpenNumberFormatPopup] = useState(false);

  const sectionDefaultStateFactory = (): UseFormBuilderStateOptionsValue => {
    const defaultState = formBuilderDtoToState({ sections: data?.slConfig?.sections ?? [] });

    return {
      onChange: (sections, fields) => {
        const dto = formBuilderStateToDto({ sections, fields });
        updateField.mutate({ id: orderTypeId, path: 'sections', value: dto.sections });
      },
      defaultSections: defaultState.sections,
      defaultFields: defaultState.fields,
    };
  };

  const productsDefaultStateFactory = (): UseFormBuilderStateOptionsValue => {
    const defaultState = formBuilderDtoToState({ sections: data?.slConfig?.productsSections ?? [] });

    return {
      onChange: (sections, fields) => {
        const dto = formBuilderStateToDto({ sections, fields });
        updateField.mutate({ id: orderTypeId, path: 'productsSections', value: dto.sections });
      },
      defaultSections: defaultState.sections,
      defaultFields: defaultState.fields,
    };
  };

  return (
    <>
      <div className={clsx('flex flex-col', className)}>
        <ParametersRow.Title className='flex justify-between items-start'>
          <span className='relative'>
            <FormattedMessage id='app.settings.order_type_config.title' values={{ value: name }} />
          </span>
          <div className='flex gap-1'>
            <Button variant='cta' size='small' onClick={() => setOpenRenamePopup(true)}>
              <PencilIcon className=' rounded-full bg-primary text-white p-1 h-6 top-[-.25rem] left-[-1.25rem] hover:bg-primaryFocus' />
            </Button>
            <Button
              variant='primary'
              rounded
              size='small'
              onClick={() => {
                resetMutation.mutate(orderTypeId);
              }}
            >
              <FormattedMessage id='app.button.reset' />
            </Button>
          </div>
        </ParametersRow.Title>

        <ParametersRow>
          <ParametersRow.Label>
            <FormattedMessage id='app.tenant.order_number_format' />
          </ParametersRow.Label>
          <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
            <span className='relative pr-1'>
              {data?.orderNumberFormat}
              <Button variant='cta' size='small' onClick={() => setOpenNumberFormatPopup(true)}>
                <PencilIcon className='absolute rounded-full bg-primary text-white p-1 h-6 bottom-[-.25rem] right-[-1.25rem] hover:bg-primaryFocus' />
              </Button>
            </span>
          </ParametersRow.Value>
        </ParametersRow>

        {fields.map(({ title, name, hint }, index) => (
          <ParametersRow key={index}>
            <ParametersRow.Label>
              <Button
                variant='cta'
                size='small'
                onClick={() =>
                  showInfoPopup({
                    title: intl.formatMessage({ id: title }),
                    content: intl.formatMessage({ id: hint }),
                  })
                }
              >
                <InformationCircleIcon className='w-6 h-6 text-primary' />
              </Button>
              <FormattedMessage id={title} />
            </ParametersRow.Label>
            <ParametersRow.Value className='w-full max-w-1/2 min-w-1/2'>
              <OrderTypeFieldToggle orderTypeId={orderTypeId} name={name} />
            </ParametersRow.Value>
          </ParametersRow>
        ))}

        <FormBuilderContainer
          title={<FormattedMessage id='app.order_type.sl_config.sections' />}
          className='mt-8'
          defaultStateOptions={sectionDefaultStateFactory}
        />
        <FormBuilderContainer
          title={<FormattedMessage id='app.order_type.sl_config.product_sections' />}
          className='mt-8'
          defaultStateOptions={productsDefaultStateFactory}
        />
      </div>

      <UpdateOrderTypeNamePopup
        open={openRenamePopup}
        onClose={() => setOpenRenamePopup(false)}
        orderTypeId={orderTypeId}
        name={data?.name}
      />

      <UpdateOrderFormatPopup
        open={openNumberFormatPopup}
        onClose={() => setOpenNumberFormatPopup(false)}
        orderTypeId={orderTypeId}
        orderNumberFormat={data?.orderNumberFormat ?? null}
      />
    </>
  );
};

export default OrderTypeSettingsForm;
