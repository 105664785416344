import { z } from 'zod';

export const OrderToExcelFiltersSchema = z.object({
  statusProp: z.array(z.string().min(1)).default([]),
  paymentMethods: z.array(z.string()).default([]),
  orderNumber: z.string().default(''),
  companyName: z.string().default(''),
  companyAddress: z.string().default(''),
  technicianIds: z.array(z.number()).default([]),
  datetime: z.string().nullable().default(null),
  companyId: z.number(),
  branchId: z.number().nullable().default(null),
  productId: z.number().nullable().default(null),
  minPrice: z.number().nullable().default(null),
  maxPrice: z.number().nullable().default(null),
  minRealStart: z.string().nullable().default(null),
  minRealEnd: z.string().nullable().default(null),
  companyProp: z.string().default(''),
  technicianProp: z.string().default(''),
});

export type OrderToExcelFiltersSchema = z.infer<typeof OrderToExcelFiltersSchema>;
