import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { CreateStockOrderRequest, StockOrderResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAddStockOrder = (): UseMutationResult<StockOrderResponse, unknown, CreateStockOrderRequest> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<StockOrderResponse, unknown, CreateStockOrderRequest>(
    async (stockOrder: CreateStockOrderRequest) => {
      const data = await client.stockOrders.createStockOrder({ requestBody: stockOrder });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.stockOrders.list.queryKey);
        toast(<FormattedMessage id='app.toasts.add_success' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useAddStockOrder;
