import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrderResponse } from 'src/api';
import { SelectRhfc } from 'src/components/fields/Select';
import { MessageId } from 'src/types/commonTypes';
import FieldWrapper from '../../../FieldWrapper';
import Section from '../../../Section';
import SectionTitle from '../../../SectionTitle';
import { FinishOrderFormValues } from '../../schema';
import { getAllowedDeliveryTypes } from './helpers';
import DeliveryTypeFormSwitch from './components/DeliveryTypeFormSwitch';

export type DeliveryFromProps = {
  order: OrderResponse;
  hiddenFields?: string[];
};

const DeliveryForm: FC<DeliveryFromProps> = ({ order, hiddenFields }) => {
  const intl = useIntl();
  const { control } = useFormContext<FinishOrderFormValues>();
  const deliveryType = useWatch<FinishOrderFormValues>({ name: 'deliveryType' });

  return (
    <>
      <SectionTitle className='mb-4'>
        <FormattedMessage id='app.finish_order.section_title.delivery' />
      </SectionTitle>
      <Section>
        {!hiddenFields?.includes('deliveryType') && (
          <FieldWrapper label={<FormattedMessage id='app.finish_order.delivery_type' />}>
            <SelectRhfc
              control={control}
              name='deliveryType'
              options={getAllowedDeliveryTypes(order)}
              getOptionLabel={(option) =>
                intl.formatMessage({
                  id: `app.finish_order.delivery_type.${option.toLowerCase()}` as MessageId,
                })
              }
              getOptionValue={(option) => option}
            />
          </FieldWrapper>
        )}
        <DeliveryTypeFormSwitch deliveryType={deliveryType} hiddenFields={hiddenFields} />
      </Section>
    </>
  );
};

export default DeliveryForm;
