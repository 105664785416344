import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Updater } from '@tanstack/react-table';
import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { PageMetaDto } from 'src/api';
import PaginationButton from './components/PaginationButton';
import PaginationGap from './components/PaginationGap';
import PaginationItem from './components/PaginationItem';
import { getVisiblePages } from './helpers';

export type PaginationProps = PageMetaDto & {
  onPageChange: (value: Updater<number>) => void;
} & HTMLAttributes<HTMLDivElement>;

const Pagination: FC<PaginationProps> = ({ className, onPageChange, totalPages, currentPage }) => {
  const currentPageInUi = currentPage + 1;
  const hasPrev = currentPageInUi > 1;
  const hasNext = currentPageInUi < (totalPages ?? 0);
  const visiblePages = getVisiblePages(currentPage, totalPages ?? 0);

  return (
    <nav
      className={clsx(
        className,
        'flex items-center box-border justify-between h-16 text-gray-600 border-t border-gray-100 px-4 sm:px-0 xs:px-0',
      )}
    >
      <PaginationButton
        disabled={!hasPrev}
        icon={<ArrowLongLeftIcon className='block h-6' aria-hidden='true' />}
        text={<FormattedMessage id='app.button.prev' />}
        onClick={() => onPageChange((value) => value - 1)}
      />

      <div className='flex gap-x-1 h-full'>
        {/* intentionally no keys here */}
        {visiblePages.map((i) =>
          i !== null ? (
            // eslint-disable-next-line react/jsx-key
            <PaginationItem selected={currentPageInUi === i} pageNumber={i} onClick={() => onPageChange(i - 1)} />
          ) : (
            // eslint-disable-next-line react/jsx-key
            <PaginationGap />
          ),
        )}
      </div>

      <PaginationButton
        disabled={!hasNext}
        className='flex-row-reverse'
        icon={<ArrowLongRightIcon className='block h-6' aria-hidden='true' />}
        text={<FormattedMessage id='app.button.next' />}
        onClick={() => onPageChange((value) => value + 1)}
      />
    </nav>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
